import { Grid, SxProps, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { MDFTooltip } from 'shared/components/tooltip/tooltip.component';
import {
  getStatus,
  getStatusValues,
  StatusKpiCardValues,
} from './status-kpi-card.viewModel';
import { KPICardFooter } from './kpi-card-footer/kpi-card-footer';
import { typography } from 'styles/typography';
import { FixedTimeline } from 'assets/fixed-timeline';
import { TEXT } from 'shared/constants/text';
import { Status } from 'shared/models/status.type';
import { Status as RAGStatus } from 'routes/environmental-monitor/models/gql/vessel-kpi-gql.model';
import { ReactElement } from 'react';

export type KpiData = {
  primaryValue: string;
  unit?: string;
  secondaryValue?: string;
  tertiaryValue?: string;
  quaternaryValue?: string;
  status: Status;
};

export type customFooterType = Array<{
  title: string;
  value?: string | number | undefined;
  unitLabel?: string;
  ignoreParentUnit?: boolean;
  excelFormat?: string | undefined;
}>;

export type footerType =
  | 'required'
  | 'target'
  | 'planned'
  | 'vessel-fleet'
  | 'eua-planned'
  | 'default-none'
  | undefined
  | customFooterType
  | ReactElement;

export interface StatusKpiCardProps {
  title: string;
  titleAlignment?: 'left' | 'center' | 'right';
  kpiData: KpiData | undefined;
  units?: string;
  unitSize?: string | undefined;
  showTooltip?: boolean;
  tooltipText?: string;
  footerType?: footerType;
  customExportDefinitions?: customFooterType;
  footerUnits?: string;
  footerUnits2?: string;
  footerUnitBeforeValue?: boolean;
  footerUnit2BeforeValue?: boolean;
  footerSize?: string;
  hideFooterDivider?: boolean;
  fixedTimeline?: boolean;
  value?: string;
  sectionVisibility?: {
    title?: boolean;
    primaryValue?: boolean;
    footer?: boolean;
  };
  sectionName?: string;
  customGetStatusValues?: (status: string) => StatusKpiCardValues;
  hideHeaderDivider?: boolean;
  isFeatureFlagEnabled?: boolean;
}

export const StatusKpiCard = (props: StatusKpiCardProps) => {
  const theme = useTheme();
  const { fontFamilies, fontWeights, lineHeights, fontSize, letterSpacing } =
    typography;

  if (props.isFeatureFlagEnabled ?? true) {
    // Continue with the rendering logic
  } else {
    return null;
  }

  const cardBackground = theme.background.component;

  const kpiStatus = getStatus(props.footerType, props.kpiData?.status);

  const { color: statusBarBackground, icon: statusIcon } =
    props.customGetStatusValues
      ? props.customGetStatusValues(props.kpiData?.status ?? RAGStatus.success)
      : getStatusValues(kpiStatus);

  const statusBarStyle: SxProps = {
    backgroundColor: statusBarBackground,
    height: 8,
    margin: 0,
  };

  const dividerStyle: SxProps = {
    backgroundColor: theme.colors.divider,
    mt: 2,
  };

  const cardStyle: SxProps = {
    borderRadius: '12px',
    backgroundColor: cardBackground,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  };

  const topBoxStyle: SxProps = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginRight: '0px',
    minHeight: lineHeights['3'],
    height: 'auto',
  };

  const boxStyle: SxProps = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
  };

  const kpiTitleStyle: SxProps = {
    fontFamily: fontFamilies.roboto,
    fontSize: fontSize['12'],
    fontWeight: fontWeights['roboto-2'],
    letterSpacing: letterSpacing['4'],
    lineHeight: lineHeights['5'],
    width: '100%',
    textAlign: props.titleAlignment ?? 'left',
  };

  const kpiPrimaryContentStyle: SxProps = {
    alignSelf: 'flex-end',
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights['roboto-2'],
    lineHeight: '42px',
    fontSize: fontSize['10'],
    letterSpacing:
      props.kpiData?.primaryValue === TEXT.ERROR_MESSAGES.EMPTY_DASHES
        ? letterSpacing['2']
        : letterSpacing['0'],
  };

  const kpiPrimaryContentAdjacentTextStyle: SxProps = {
    alignSelf: 'flex-end',
    pl: 1,
    pb: '5.5px',
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights['roboto-2'],
    lineHeight: lineHeights['5'],
    fontSize: props.unitSize ?? fontSize['12'],
    letterSpacing: letterSpacing['4'],
  };

  const unit =
    props.kpiData?.primaryValue === TEXT.ERROR_MESSAGES.EMPTY_DASHES
      ? ''
      : props.kpiData?.unit ?? '';

  const secondRowVisible =
    props.sectionVisibility?.primaryValue !== false ? 'flex' : 'none';

  return (
    <Box
      component={'section'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        component={'article'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          flexGrow: 1,
        }}
      >
        {/* Card Container */}
        <Card sx={cardStyle}>
          {!props.hideHeaderDivider && <Divider sx={statusBarStyle} />}
          <CardContent
            sx={{
              m: '24px',
              alignContent: 'center',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box sx={topBoxStyle}>
              <Typography sx={kpiTitleStyle}>{props.title}</Typography>
              {props?.showTooltip && props?.tooltipText && (
                <MDFTooltip tooltipText={props?.tooltipText} />
              )}
              {props.fixedTimeline && (
                <Box sx={{ alignSelf: 'flex-end' }}>
                  <FixedTimeline fillOpacity={0.5} />
                </Box>
              )}
            </Box>
            <Box
              sx={{
                ...boxStyle,
                paddingTop: 2,
                alignItems: 'center',
                display: secondRowVisible,
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography sx={kpiPrimaryContentStyle}>
                  {props.kpiData?.primaryValue + unit}
                </Typography>
                {props.units &&
                  props.units !== TEXT.ERROR_MESSAGES.EMPTY_DASHES && (
                    <Typography sx={kpiPrimaryContentAdjacentTextStyle}>
                      {props.units &&
                      props.kpiData?.primaryValue !==
                        TEXT.ERROR_MESSAGES.EMPTY_DASHES
                        ? props.units
                        : ''}
                    </Typography>
                  )}
              </Box>
              <Box>
                {statusIcon && (
                  <img
                    src={statusIcon}
                    alt='status'
                    style={{ display: 'block' }}
                  />
                )}
              </Box>
            </Box>
            {props.sectionVisibility?.footer && (
              <>
                {!props.hideFooterDivider && <Divider sx={dividerStyle} />}
                <Box sx={{ ...boxStyle }}>
                  <KPICardFooter
                    footerSize={props.footerSize}
                    footerType={props.footerType}
                    footerUnits={props.footerUnits}
                    footerUnits2={props.footerUnits2}
                    footerUnitBeforeValue={props.footerUnitBeforeValue}
                    footerUnit2BeforeValue={props.footerUnit2BeforeValue}
                    kpiData={props.kpiData}
                  />
                </Box>
              </>
            )}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export const GroupedKPI = (props: {
  title: string;
  cardProps: StatusKpiCardProps;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Typography variant='h6' sx={{ marginBottom: 1, minHeight: '24px' }}>
        {props.title}
      </Typography>
      <StatusKpiCard {...props.cardProps} />
    </Box>
  );
};

export const RenderKPIGroup = (props: {
  title: string;
  kpiData: StatusKpiCardProps[];
}) => {
  return (
    <>
      {props.kpiData.map((cardProps, ix) => (
        <GridItemKpiContainer key={cardProps.title}>
          <GroupedKPI
            title={ix === 0 ? props.title : ''}
            cardProps={cardProps}
          />
        </GridItemKpiContainer>
      ))}
    </>
  );
};

export const GridItemKpiContainer = (props: {
  children?: JSX.Element | JSX.Element[];
}) => (
  <Grid item xs={12} smPlus={6} mdPlus={4} xl={3}>
    {props.children}
  </Grid>
);

export const KpiCardGridContainer = (props: {
  children?: JSX.Element | JSX.Element[];
}) => (
  <Grid
    container
    columns={12}
    columnSpacing={3}
    rowSpacing={2}
    sx={{ marginTop: 1 }}
  >
    {props.children}
  </Grid>
);

export const KpiCardDivider = () => (
  <Grid item sx={{ pl: '0 !important', translate: '12px', marginLeft: '-1px' }}>
    <Divider orientation='vertical' />
  </Grid>
);
