import { Grid } from '@mui/material';
import { useGetFleetKpis } from 'routes/environmental-monitor/services/fleet.service';
import { KpiCardError } from 'shared/components/error/kpi-card-error.component';
import { KpiCardSkeleton } from 'shared/components/kpi-card-skeleton/kpi-card-skeleton.component';
import {
  StatusKpiCard,
  StatusKpiCardProps,
} from 'shared/components/status-kpi-card/status-kpi-card.component';
import { TEXT } from 'shared/constants/text';
import { toStatusEnum } from 'shared/utils/status-utils';
import { ExcelExportContext } from 'shared/models/excel-export.context.model';
import { useContext, useEffect } from 'react';
import { formatNumber } from 'shared/utils/float-utils';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { DateRange } from 'shared/utils/date-utc-helper';
import { ExportableSections } from 'shared/utils/valid-sections';
import { useFeatureToggleContext } from 'shared/components/contexts/featureToggleProvider.component';
import { FEATURE_FLAG } from 'shared/constants/feature-flag';

export type FleetKpiDataSummaryProps = {
  dateRange: DateRange | null;
};

export const FleetKpiDataSummary: React.FC<FleetKpiDataSummaryProps> = ({
  dateRange,
}) => {
  const { setCardData } = useContext(ExcelExportContext);
  const { imoNumbers } = useContext(NavBarContext);
  const { featureFlags } = useFeatureToggleContext();
  const { data, loading, error } = useGetFleetKpis(dateRange, imoNumbers);

  const EeoiCardTitle = 'EEOI';

  const eeoiActual = data?.kpis?.eeoi?.actual;
  const eeoiTarget = data?.kpis?.eeoi?.target;
  const eeoiDeviation = data?.kpis?.eeoi?.deviation;
  const eeoiStatus = toStatusEnum(data?.kpis?.eeoi?.status ?? '');
  const section = ExportableSections['IMO-Overview'];

  const EeoiCardProps: StatusKpiCardProps = {
    title: EeoiCardTitle,
    sectionVisibility: { footer: true },
    footerType: 'target',
    footerUnits: TEXT.FORMULAS.EEOI,
    kpiData: {
      primaryValue: formatNumber(eeoiDeviation),
      secondaryValue: formatNumber(eeoiActual, 2),
      tertiaryValue: formatNumber(eeoiTarget, 2),
      status: eeoiDeviation ? eeoiStatus : 'unknown',
      unit: TEXT.UNIT_MEASUREMENT.PERCENTAGE,
    },
    isFeatureFlagEnabled:
      featureFlags[FEATURE_FLAG.FLEETOVERVIEW_IMO_EEOI_KPI_CARD] ?? true,
    sectionName: section,
  };
  useEffect(() => {
    setCardData([EeoiCardProps], section);
  }, [loading]);

  if (error) {
    return <KpiCardError kpiCards={[{ title: EeoiCardTitle }]} />;
  }

  if (loading || data === undefined)
    return (
      <KpiCardSkeleton kpiCards={[{ sectionVisibility: { footer: true } }]} />
    );

  return (
    <Grid container spacing={2} sx={{ marginTop: 1 }} data-testid='kpi-grid'>
      {
        <Grid item xs={12} smPlus={6} mdPlus={4} lg={3} data-testid='kpi-eeoi'>
          <StatusKpiCard {...EeoiCardProps} />
        </Grid>
      }
    </Grid>
  );
};
