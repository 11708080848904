export const FEATURE_FLAG = {
  EUA_VOYAGE_CROSSING_YEARS: '2.2_71716_EUA_Voyage_CrossingYears',
  MRV_PART_2: '2.2_60502_MRV_Part_2',
  FUEL_EU_PLANNED_ITINERARY: '2.2_117788_FuelEU_PlannedItinerary',
  FUEL_EU_GHG_TREND_CHART: '2.2_116542_FuelEU_GHG_Trend_Chart',
  GHG_MRV_PLANNED_ITINERARY: '2.2_EM_VesselOverview_PlannedItinerary_GHG_MRV',
  FLEETOVERVIEW_KPIS_GHGKPI: '2.2_EM_FleetOverview_KPIs_GhgKpi_GHG_MRV',
  FLEETOVERVIEW_KPIS_GHGKPI_SUMMARY:
    '2.2_EM_FleetOverview_KPIs_GhgKpiSummary_GHG_MRV',
  FLEETOVERVIEW_IMO_EEOI_KPI_CARD:
    'EM_FleetOverview_IMO_Overview_EEOI_KPI_Card',
  VESSELOVERVIEW_VOYAGECOMPARISONGRID_GHG:
    '2.2_EM_VesselOverview_VoyageComparisonGrid_GHG_MRV',
  FLEETOVERVIEW_VESSELCOMPARISONGRID_GHG_MRV:
    '2.2_EM_FleetOverview_VesselComparisonGrid_GHG_MRV',
  VESSELOVERVIEW_KPIS_GHGKPI_SUMMARY:
    '2.2_EM_VesselOverview_KPIs_GhgKpiSummary_GHG_MRV',
  VESSELOVERVIEW_KPIS_GHGKPI_GHG_MRV:
    '2.2_EM_VesselOverview_KPIs_GhgKpi_GHG_MRV',
  VESSELOVERVIEW_KPIS_GHGKPI: '2.2_EM_VesselOverview_KPIs_GhgKpi_GHG_MRV',
  FLEETOVERVIEW_GHG_INTENSITY_KPI_CARD:
    '2.2_EM_FleetOverview_GHG_Intensity_KPI_Card',
  FLEETOVERVIEW_WELL_TO_WAKE_SUMMARY_KPI_CARD:
    '2.2_EM_FleetOverview_Well_to_Wake_Summary_KPI_Card',
  FLEETOVERVIEW_FUEL_USAGE_KPI_CARD: '2.2_EM_FleetOverview_Fuel_Usage_KPI_Card',
  FLEETOVERVIEW_FUEL_EU_GREENHOUSE_GAS_INTENSITY_TREND_CHART:
    '2.2_EM_FleetOverview_FuelEU_Greenhouse_Gas_Intensity_Trend_Chart',
  FLEETOVERVIEW_GHG_INTENSITY_WTT_COLUMN_VESSEL_ENVIRONMENTAL_GRID:
    '2.2_EM_FleetOverview_GHG_Intensity_WtT_Column_Vessel_Environmental_Grid',
  FLEETOVERVIEW_GHG_INTENSITY_TTW_COLUMN_VESSEL_ENVIRONMENTAL_GRID:
    '2.2_EM_FleetOverview_GHG_Intensity_TtW_Column_Vessel_Environmental_Grid',
  FLEETOVERVIEW_GHG_INTENSITY_WTW_COLUMN_VESSEL_ENVIRONMENTAL_GRID:
    '2.2_EM_FleetOverview_GHG_Intensity_WtW_Column_Vessel_Environmental_Grid',
  FLEETOVERVIEW_COMPLIANCE_BALANCE_COLUMN_VESSEL_ENVIRONMENTAL_GRID:
    '2.2_EM_FleetOverview_Compliance_Balance_Column_Vessel_Environmental_Grid',
  FLEETOVERVIEW_FUEL_EU_PENALTY_AGAINST_FLEET_COLUMN_VESSEL_ENVIRONMENTAL_GRID:
    '2.2_EM_FleetOverview_FuelEU_Penalty_Against_Fleet_Column_Vessel_Environmental_Grid',
  FLEETOVERVIEW_FUEL_EU_PENALTY_COLUMN_VESSEL_ENVIRONMENTAL_GRID:
    '2.2_EM_FleetOverview_FuelEU_Penalty_Column_Vessel_Environmental_Grid',
  VESSEL_OVERVIEW_GHG_INTENSITY_KPI_CARD:
    '2.2_EM_Vessel_Overview_GHG_Intensity_KPI_Card',
  VESSEL_OVERVIEW_WELL_TO_WAKE_SUMMARY_KPI_CARD:
    '2.2_EM_Vessel_Overview_Well_to_Wake_Summary_KPI_Card',
  VESSEL_OVERVIEW_FUEL_EU_PENALTY_AGAINST_FLEET_KPI_CARD:
    '2.2_EM_Vessel_Overview_FuelEU_Penalty_Against_Fleet_KPI_Card',
  VESSEL_OVERVIEW_GHG_INTENSITY_WTT_COLUMN_VOYAGE_LEG_GRID:
    '2.2_EM_Vessel_Overview_GHG_Intensity_WtT_Column_Voyage_Leg_Grid',
  VESSEL_OVERVIEW_GHG_INTENSITY_TTW_COLUMN_VOYAGE_LEG_GRID:
    '2.2_EM_Vessel_Overview_GHG Intensity_TtW_Column_Voyage_Leg_Grid',
  VESSEL_OVERVIEW_GHG_INTENSITY_WTW_COLUMN_VOYAGE_LEG_GRID:
    '2.2_EM_Vessel_Overview_GHG Intensity_WtW_Column_Voyage_Leg_Grid',
  VESSEL_OVERVIEW_COMPLIANCE_BALANCE_COLUMN_VOYAGE_LEG_GRID:
    '2.2_EM_Vessel_Overview_Compliance_Balance_Column_Voyage_Leg_Grid',
  VESSEL_OVERVIEW_FUEL_EU_PENALTY_COLUMN_VOYAGE_LEG_GRID:
    '2.2_EM_Vessel_Overview_FuelEU_Penalty_Column_Voyage_Leg_Grid',
  VESSEL_OVERVIEW_ONSHORE_POWER_SUPPLY_COLUMN_VOYAGE_LEG_GRID:
    '2.2_EM_Vessel_Overview_Onshore_Power_Supply_Column_Voyage_Leg_Grid',
  VESSEL_OVERVIEW_GHG_INTENSITY_WTW_COLUMN_VESSEL_PLANNED_ITINERARY_GRID:
    '2.2_EM_Vessel_Overview_GHG_Intensity_WtW_Column_Vessel_Planned_Itinerary_Grid',
  VESSEL_OVERVIEW_COMPLIANCE_BALANCE_COLUMN_VESSEL_PLANNED_ITINERARY_GRID:
    '2.2_EM_Vessel_Overview_Compliance_Balance_Column_Vessel_Planned_Itinerary_Grid',
  VESSEL_OVERVIEW_FUEL_EU_PENALTY_COLUMN_VESSEL_PLANNED_ITINERARY_GRID:
    '2.2_EM_Vessel_Overview_FuelEU_Penalty_Column_Vessel_Planned_Itinerary_Grid',
  VESSEL_OVERVIEW_FUEL_EU_PLANNED_ITINERARY_KPI:
    '2.3_EM_Vessel_Overview_Fuel_EU_Planned_Itinerary_KPI',
  DASHBOARD_VESSELS_KPIS_COMPARISON_GRID_GHG_MRV:
    '2.2_Dashboard_VesselKPIsComparisonGrid_Ghg_GhgMrv',
  DASHBOARD_VESSELS_KPIS_COMPARISON_GRID_FUEL_EU:
    '2.3_Dashboard_VesselKPIsComparisonGrid_FuelEu',
  VESSEL_OVERVIEW_IMO_AER_KPI_CARD:
    'EM_VesselOverview_IMO_Overview_AER_KPI_Card',
  VESSEL_OVERVIEW_IMO_CII_KPI_CARD:
    'EM_VesselOverview_IMO_Overview_CII_KPI_Card',
  VESSEL_OVERVIEW_IMO_EEOI_KPI_CARD:
    'EM_VesselOverview_IMO_Overview_EEOI_KPI_Card',
  VESSEL_OVERVIEW_IMO_CII_YTD_KPI_CARD:
    'EM_VesselOverview_IMO_Overview_CII_YTD_KPI_Card',
  VESSEL_OVERVIEW_IMO_CII_EOY_KPI_CARD:
    'EM_VesselOverview_IMO_Overview_CII_EOY_KPI_Card',
};
