import { CellHeader } from 'routes/vessel-data-monitor/components/fleetDataComparison/table/util/fleet-data-comparison-table.util';
import { theme } from 'styles/theme';

import * as ExcelJs from 'exceljs';
import { StatusKpiCardProps } from 'shared/components/status-kpi-card/status-kpi-card.component';

import { getCardTitles } from 'shared/components/status-kpi-card/kpi-card-footer/kpi-card-footer';
import { formatNumber } from '../../../float-utils';
import { SectionName } from '../../../valid-sections';
import { setCellBackgroundColor, getStatusColor } from './styles';
import { WORKSHEET } from 'shared/utils/worksheet';

export const AddHeaderRows = (
  cellheaders: CellHeader[] | undefined,
  worksheet: ExcelJs.Worksheet
) => {
  const headerValues = cellheaders?.map((item) => item.label) || [];
  const row = worksheet.addRow(headerValues);
  row.eachCell((cell) => setCellBackgroundColor(cell, theme.excelColour.grey));
};

export const checkCards = (
  worksheet: ExcelJs.Worksheet,
  cardData: StatusKpiCardProps[],
  sectionName: SectionName
) => {
  if (sectionName === 'Alerts Summary') {
    Object.values(cardData).forEach((card) => {
      AddCardsDataAlerts(worksheet, card.title, card.value ?? 0);
    });
    return;
  }
  kpiExport(worksheet, cardData);
  worksheet.addRow(WORKSHEET.EMPTY_ROW);
};

const kpiExport = (
  worksheet: ExcelJs.Worksheet,
  cardData: StatusKpiCardProps[]
) => {
  Object.entries(cardData).forEach(([_, card]) => {
    if (card?.customExportDefinitions) {
      AddCustomExportCardsData(worksheet, card);
      worksheet.addRow(WORKSHEET.EMPTY_ROW);
    } else if (card?.sectionVisibility?.footer) {
      const unitValue = card.kpiData?.unit ?? card?.units;
      const unit = unitValue ? ` (${unitValue})` : '';
      const cardHeaders: CellHeader[] = [{ id: 1, label: card.title + unit }];
      const footerTitles = getCardTitles(card);
      const footerUnit = card.footerUnits ? ` (${card.footerUnits})` : '';
      for (let i = 0; i < footerTitles.length; i++) {
        cardHeaders.push({
          id: i + 1,
          label: footerTitles[i]?.title + footerUnit,
        });
      }

      // add Cards data to worksheet
      AddHeaderRows(cardHeaders, worksheet);
      AddCardsData(worksheet, card || '');
      worksheet.addRow(WORKSHEET.EMPTY_ROW);
    } else {
      AddCardsDataBasic(worksheet, card.title, card ?? '');
      worksheet.addRow(WORKSHEET.EMPTY_ROW);
    }
  });
};

const AddCardsDataBasic = (
  worksheet: ExcelJs.Worksheet,
  title: string,
  value: StatusKpiCardProps
) => {
  if (typeof value === 'object' && value !== null) {
    const unit = value.kpiData?.unit ?? value?.units;
    const unitTitle = unit ? ` (${unit})` : '';
    title = title + unitTitle;
    const titleRow = worksheet.addRow([title]);
    const valueRow = worksheet.addRow([value.kpiData?.primaryValue]);
    setCellBackgroundColor(titleRow.getCell(1), theme.excelColour.grey);
    setCellBackgroundColor(
      valueRow.getCell(1),
      getStatusColor(value.kpiData?.status)
    );
    return;
  }

  worksheet.addRow([title]);
  worksheet.addRow([value]);
};

const AddCardsData = (
  worksheet: ExcelJs.Worksheet,
  value: StatusKpiCardProps | string | number
) => {
  if (typeof value === 'object' && value !== null) {
    const footerTitles = getCardTitles(value);
    const noDecimals = footerTitles[0]?.noDecimals;
    const rowData = [];
    rowData.push(formatNumber(value.kpiData?.primaryValue, noDecimals));
    for (let i = 0; i < footerTitles.length; i++) {
      switch (i) {
        case 0:
          rowData.push(formatNumber(value.kpiData?.secondaryValue, noDecimals));
          break;
        case 1:
          rowData.push(formatNumber(value.kpiData?.tertiaryValue, noDecimals));
          break;
        case 2:
          rowData.push(
            formatNumber(value.kpiData?.quaternaryValue, noDecimals)
          );
          break;
        default:
          break;
      }
    }

    const row = worksheet.addRow(rowData);
    setCellBackgroundColor(
      row.getCell(1),
      getStatusColor(value.kpiData?.status)
    );
  }
};

const AddCustomExportCardsData = (
  worksheet: ExcelJs.Worksheet,
  card: StatusKpiCardProps
) => {
  if (card.customExportDefinitions !== null) {
    const unitValue = card.kpiData?.unit ?? card?.units;
    const unit = unitValue ? ` (${unitValue})` : '';
    const cardHeaders: CellHeader[] = [{ id: 1, label: card.title + unit }];
    for (let i = 0; i < card.customExportDefinitions!.length; i++) {
      cardHeaders.push({
        id: i + 1,
        label:
          card.customExportDefinitions![i]?.title +
          (card.customExportDefinitions![i]?.ignoreParentUnit ? '' : unit),
      });
    }
    AddHeaderRows(cardHeaders, worksheet);
    const rowData = [],
      formats: (string | undefined)[] = [undefined];
    rowData.push(card.kpiData?.primaryValue);
    card.customExportDefinitions?.forEach((currentItem) => {
      formats.push(currentItem.excelFormat);
      rowData.push(currentItem.value);
    });
    // Add row and apply formats
    const row = worksheet.addRow(rowData);
    row.eachCell((cell, colNumber) => {
      const colNum = colNumber - 1;
      if (formats[colNum]) {
        cell.numFmt = formats[colNum];
      }
    });
    setCellBackgroundColor(
      row.getCell(1),
      getStatusColor(card.kpiData?.status)
    );
  }
};

const AddCardsDataAlerts = (
  worksheet: ExcelJs.Worksheet,
  title: string,
  value: number | string
) => {
  const row = worksheet.addRow([title, value]);
  setCellBackgroundColor(row.getCell(1), theme.excelColour.grey);
  setCellBackgroundColor(row.getCell(2), getStatusColor(title));
};
export { getStatusColor };

export const flattenYearlyStatusKpiCards = <
  T extends { year: number; data: StatusKpiCardProps[] }
>(
  array: T[]
): StatusKpiCardProps[] => {
  const result: StatusKpiCardProps[] = [];
  array.forEach((item) => {
    item.year &&
      result.push({
        title: 'FuelEU ' + item.year.toString(),
      } as StatusKpiCardProps);
    result.push(...item.data);
  });
  return result;
};
