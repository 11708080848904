import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useGetVoyageComparison } from 'routes/environmental-monitor/services/vessel.service';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { vesselPageTitles } from 'routes/environmental-monitor/view-models/titles.viewModel';
import { CardContainer } from 'shared/components/navigation/cardContainer.component';
import {
  DataGridTable,
  GridColumn,
  GridColumnDef,
  GridCustomHeaderClass,
  GridRAGColumn,
  NA_VALUE_INDICATOR,
  nameof,
} from 'shared/components/datagrid';
import {
  IEuaByYear,
  IFuelEU,
  IVoyageComparisonField,
  IVoyageComparisonLegDomain,
} from 'routes/environmental-monitor/models/voyage-comparison.model';
import { TEXT } from 'shared/constants/text';
import { RAGFilter } from 'shared/components/rag-filter/grid-filter.component';
import { useFilter } from 'shared/components/rag-filter/filter.hook';
import { IRagFilterData } from 'routes/dashboard/models/fleet-kpis.model';
import { euaLiabilitiesTooltipHeader } from '../../tooltip/eua-tooltip.component';
import {
  formatLargeNumber,
  formatNumber,
  stringToNumber,
} from 'shared/utils/float-utils';
import { Tooltip } from '@mui/material';
import {
  GridCellParams,
  GridPinnedPosition,
  GridTreeNodeWithRender,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro';
import { UTCDate } from 'shared/utils/date-utc-helper';
import { Box } from '@mui/system';
import { useFeatureToggleContext } from 'shared/components/contexts/featureToggleProvider.component';
import { FEATURE_FLAG } from 'shared/constants/feature-flag';
import { EmissionKey } from '_gql/graphql';
import {
  EmissionsType,
  EmissionsTypeDisplay,
} from 'routes/environmental-monitor/models/vessel-kpi.model';
import { Status } from 'shared/models/status.type';
import { RAGDisplay } from 'shared/components/status-card/ragIndicator.component';
import { dashes } from 'shared/utils/display-utils';

interface IVoyageComparisonLegDomainWithStatus
  extends IVoyageComparisonLegDomain {
  realCalculations: {
    cii?: IRagFilterData;
    ciiDeviation?: IRagFilterData;
    eeoiDeviation?: IRagFilterData;
    aerDeviation?: IRagFilterData;
  };
  remarksColumns: {
    calculations: {
      eua: {
        euCo2?: string | undefined;
        liability?: string | undefined;
      };
      ghgMrv: {
        totalCh4?: string | undefined;
        totalN2o?: string | undefined;
        fuelSlippageByYear?: string | undefined;
        ghgByYear?: string | undefined;
      };
      fuelEU: {
        wellToTank?: string | undefined;
        wellToWake?: string | undefined;
        tankToWake?: string | undefined;
        complianceBalance?: string | undefined;
        fuelPenalty?: string | undefined;
        onShorePowerSupply?: string | undefined;
      };
    };
  };
}

const SMALL_CELL_WIDTH = 135;
const BIG_CELL_WIDTH = 180;

export function getColumnsDefinition(
  featureFlags: {
    [key: string]: boolean;
  },
  isMultiYearData = false
): GridColumnDef[] {
  const columns = nameof<IVoyageComparisonLegDomainWithStatus>; // get the properties from the model

  // Access the feature flags from the context directly
  const crossingYearsFeatureFlagEnabled = featureFlags[FEATURE_FLAG.MRV_PART_2];

  const ghgMrvFeatureFlagEnabled = featureFlags[FEATURE_FLAG.MRV_PART_2];

  const ghgMrvStoryFeatureFlagEnabled = featureFlags[FEATURE_FLAG.MRV_PART_2];

  const vesselOverviewGhgIntensityWttColumnVoyageLegGrid =
    featureFlags[
      FEATURE_FLAG.VESSEL_OVERVIEW_GHG_INTENSITY_WTT_COLUMN_VOYAGE_LEG_GRID
    ];
  const vesselOverviewGhgIntensityTtwColumnVoyageLegGrid =
    featureFlags[
      FEATURE_FLAG.VESSEL_OVERVIEW_GHG_INTENSITY_TTW_COLUMN_VOYAGE_LEG_GRID
    ];
  const vesselOverviewGhgIntensityWtwColumnVoyageLegGrid =
    featureFlags[
      FEATURE_FLAG.VESSEL_OVERVIEW_GHG_INTENSITY_WTW_COLUMN_VOYAGE_LEG_GRID
    ];
  const vesselOverviewComplianceBalanceColumnVoyageLegGrid =
    featureFlags[
      FEATURE_FLAG.VESSEL_OVERVIEW_COMPLIANCE_BALANCE_COLUMN_VOYAGE_LEG_GRID
    ];
  const vesselOverviewFuelEuPenaltyColumnVoyageLegGrid =
    featureFlags[
      FEATURE_FLAG.VESSEL_OVERVIEW_FUEL_EU_PENALTY_COLUMN_VOYAGE_LEG_GRID
    ];
  const vesselOverviewOnshorePowerSupplyColumnVoyageLegGrid =
    featureFlags[
      FEATURE_FLAG.VESSEL_OVERVIEW_ONSHORE_POWER_SUPPLY_COLUMN_VOYAGE_LEG_GRID
    ];
  const multiYearDataVisibility =
    crossingYearsFeatureFlagEnabled && isMultiYearData
      ? 'export-only'
      : 'hidden';

  const ret: GridColumnDef[] = [
    GridColumn({
      field: columns('legNumber.displayValue'),
      headerName: 'Leg #',
      pinPosition: GridPinnedPosition.left,
      valueField: columns('legNumber.fieldValue'),
      minWidth: 90,
    }),
    GridRAGColumn({
      field: columns('realCalculations.cii'),
      headerName: 'CII \nRating',
      type: 'string',
      headerClassName: GridCustomHeaderClass.BorderLeft,
      minWidth: SMALL_CELL_WIDTH,
    }),
    GridColumn({
      field: columns('calculations.cii.delta.displayValue'),
      headerName: 'CII \nΔ',
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      valueField: columns('calculations.cii.delta.fieldValue'),
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 2,
    }),
    GridRAGColumn({
      field: columns('calculations.ciiPercentage.deviation.fieldValue'),
      headerName: `CII \n${TEXT.UNIT_MEASUREMENT.PERCENTAGE}`,
      type: 'number',
      valueField: columns('calculations.ciiPercentage.deviation.fieldValue'),
      statusField: columns('calculations.ciiPercentage.status'),
      headerClassName: GridCustomHeaderClass.BorderRight,
      minWidth: SMALL_CELL_WIDTH,
    }),
    GridColumn({
      field: columns('calculations.eeoi.value.displayValue'),
      headerName: `EEOI \n(${TEXT.FORMULAS.EEOI})`,
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueField: columns('calculations.eeoi.value.fieldValue'),
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 2,
    }),
    GridColumn({
      field: columns('calculations.eeoi.delta.displayValue'),
      headerName: 'EEOI \nΔ',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueField: columns('calculations.eeoi.delta.fieldValue'),
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 2,
    }),
    GridRAGColumn({
      field: columns('calculations.eeoi.deviation.fieldValue'),
      headerName: `EEOI \n${TEXT.UNIT_MEASUREMENT.PERCENTAGE}`,
      type: 'number',
      valueField: columns('calculations.eeoi.deviation.fieldValue'),
      statusField: columns('calculations.eeoi.status'),
      headerClassName: GridCustomHeaderClass.BorderRight,
      minWidth: SMALL_CELL_WIDTH,
    }),
    GridColumn({
      field: columns('calculations.aer.value.displayValue'),
      headerName: `AER \n(${TEXT.FORMULAS.AER})`,
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueField: columns('calculations.aer.value.fieldValue'),
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 2,
    }),
    GridColumn({
      field: columns('calculations.aer.delta.displayValue'),
      headerName: 'AER \nΔ',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueField: columns('calculations.aer.delta.fieldValue'),
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 2,
    }),
    GridRAGColumn({
      field: columns('calculations.aer.deviation.fieldValue'),
      headerName: `AER \n${TEXT.UNIT_MEASUREMENT.PERCENTAGE}`,
      type: 'number',
      valueField: columns('calculations.aer.deviation.fieldValue'),
      statusField: columns('calculations.aer.status'),
      headerClassName: GridCustomHeaderClass.BorderRight,
      minWidth: SMALL_CELL_WIDTH,
    }),
    {
      field: columns('departurePort.displayValue'),
      headerName: 'Departure \nPort',
      type: 'string',
      align: 'left',
      headerAlign: 'left',
      valueGetter: (params) =>
        params.row.departurePort.displayValue ??
        TEXT.ERROR_MESSAGES.EMPTY_DASHES,
      minWidth: BIG_CELL_WIDTH,
    },
    {
      field: columns('departureDate.fieldValue'),
      headerName: 'Departure \nDate',
      type: 'date',
      align: 'left',
      headerAlign: 'left',
      valueGetter: (params) => params.row.departureDate.fieldValue as UTCDate,
      valueFormatter: (params) =>
        params?.value?.formatDMY() ?? TEXT.ERROR_MESSAGES.EMPTY_DASHES,
      headerClassName: GridCustomHeaderClass.BorderRight,
      minWidth: SMALL_CELL_WIDTH,
    },
    {
      field: columns('arrivalPort.displayValue'),
      headerName: 'Arrival Port',
      type: 'string',
      align: 'left',
      headerAlign: 'left',
      valueGetter: (params) =>
        params.row.arrivalPort.displayValue ?? TEXT.ERROR_MESSAGES.EMPTY_DASHES,
      minWidth: BIG_CELL_WIDTH,
    },
    {
      field: columns('arrivalDate.fieldValue'),
      headerName: 'Arrival \nDate',
      type: 'date',
      align: 'left',
      headerAlign: 'left',
      valueGetter: (params) => params.row.arrivalDate.fieldValue as UTCDate,
      valueFormatter: (params) =>
        params?.value?.formatDMY() ?? TEXT.ERROR_MESSAGES.EMPTY_DASHES,
      headerClassName: GridCustomHeaderClass.BorderRight,
      minWidth: SMALL_CELL_WIDTH,
    },
    GridColumn({
      field: columns('distance.displayValue'),
      headerName: `Distance \n(${TEXT.UNIT_MEASUREMENT.NAUTICAL_MILE})`,
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueField: columns('distance.fieldValue'),
      minWidth: SMALL_CELL_WIDTH,
    }),
    GridColumn({
      field: columns('speed.displayValue'),
      headerName: `Speed \n(${TEXT.UNIT_MEASUREMENT.KNOTS})`,
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueField: columns('speed.fieldValue'),
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 2,
    }),
    {
      field: columns('fuelConsumed.displayValue'),
      headerName: `Fuel \nConsumed \n(${TEXT.UNIT_MEASUREMENT.METRIC_TONNES})`,
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) =>
        params.row.fuelConsumed.fieldValue ?? TEXT.ERROR_MESSAGES.EMPTY_DASHES,
      minWidth: SMALL_CELL_WIDTH,
    },
    GridColumn({
      field: columns('co2Emissions.displayValue'),
      headerName: `Total \nCO₂ \n(${TEXT.UNIT_MEASUREMENT.METRIC_TONNES})`,
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueField: columns('co2Emissions.fieldValue'),
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 2,
    }),
    GridColumn({
      field: columns('calculations.eua.voyageType.displayValue'),
      headerName: 'EU \nVoyage',
      type: 'string',
      align: 'right',
      headerAlign: 'right',
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 2,
      valueField: columns('calculations.eua.voyageType.fieldValue'),
      valueFormatter: (params: any) => euaExcelDisplayValue(params),
    }),
    GridColumn({
      field: columns('calculations.eua.euCo2.displayValue'),
      headerName: `EU CO₂\n(${TEXT.UNIT_MEASUREMENT.METRIC_TONNES_MRV})`,
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      minWidth: SMALL_CELL_WIDTH,
      valueField: columns('calculations.eua.euCo2.fieldValue'),
      valueFormatter: (params) => euaExcelDisplayValue(params, 2),
      renderCell: (params: any) =>
        renderEmissionCell(
          params,
          EmissionsType.CO2,
          params.row?.calculations?.ghgMrv?.totalCo2,
          EmissionsTypeDisplay.CO2
        ),
    }),
    GridColumn({
      field: columns('remarksColumns.calculations.eua.euCo2'),
      headerName: `EU CO₂\n(${TEXT.UNIT_MEASUREMENT.METRIC_TONNES_MRV}) Remarks`,
      type: 'string',
      align: 'right',
      headerAlign: 'right',
      minWidth: BIG_CELL_WIDTH,
      valueField: columns('remarksColumns.calculations.eua.euCo2'),
      visibility: multiYearDataVisibility,
    }),
  ];
  if (ghgMrvFeatureFlagEnabled && ghgMrvStoryFeatureFlagEnabled) {
    ret.push(
      GridColumn({
        field: columns('calculations.ghgMrv.totalCh4'),
        headerName: 'EU CH₄ \n(t,mrv)',
        type: '{number,number}[]',
        align: 'right',
        headerAlign: 'right',
        minWidth: SMALL_CELL_WIDTH,
        valueField: columns('calculations.ghgMrv.totalCh4'),
        noDecimals: 2,
        valueFormatter: (params) => euaExcelDisplayValue(params, 2),
        renderCell: (params: any) =>
          renderEmissionCell(
            params,
            EmissionsType.CH4,
            params.row?.calculations?.ghgMrv?.totalCh4,
            EmissionsTypeDisplay.CH4
          ),
      }),
      GridColumn({
        field: columns('remarksColumns.calculations.ghgMrv.totalCh4'),
        headerName: 'EU CH₄ \n(t,mrv) Remarks',
        type: 'string',
        align: 'right',
        headerAlign: 'right',
        minWidth: BIG_CELL_WIDTH,
        valueField: columns('remarksColumns.calculations.ghgMrv.totalCh4'),
        visibility: multiYearDataVisibility,
      }),
      GridColumn({
        field: columns('calculations.ghgMrv.totalN2o'),
        headerName: 'EU N₂O \n(t,mrv)',
        type: '{number,number}[]',
        align: 'right',
        headerAlign: 'right',
        minWidth: SMALL_CELL_WIDTH,
        valueField: columns('calculations.ghgMrv.totalN2o'),
        noDecimals: 2,
        valueFormatter: (params) => euaExcelDisplayValue(params, 2),
        renderCell: (params: any) =>
          renderEmissionCell(
            params,
            EmissionsType.N2O,
            params.row?.calculations?.ghgMrv.totalN2o,
            EmissionsTypeDisplay.N2O
          ),
      }),
      GridColumn({
        field: columns('remarksColumns.calculations.ghgMrv.totalN2o'),
        headerName: 'EU N₂O \n(t,mrv) Remarks',
        type: 'string',
        align: 'right',
        headerAlign: 'right',
        minWidth: BIG_CELL_WIDTH,
        valueField: columns('remarksColumns.calculations.ghgMrv.totalN2o'),
        visibility: multiYearDataVisibility,
      }),
      GridColumn({
        field: columns('calculations.ghgMrv.fuelSlippageByYear'),
        headerName: 'EU Fuel Slip \n(t,mrv)',
        type: '{number,number}[]',
        align: 'right',
        headerAlign: 'right',
        minWidth: SMALL_CELL_WIDTH,
        valueField: columns('calculations.ghgMrv.fuelSlippageByYear'),
        valueFormatter: (params) => ghgGetStandardKvpColumn(params),
        renderCell: (params: any) =>
          renderCellWithToolTip(
            params,
            'Fuel Slip',
            params.row?.calculations?.ghgMrv?.fuelSlippageByYear
          ),
      }),
      GridColumn({
        field: columns('remarksColumns.calculations.ghgMrv.fuelSlippageByYear'),
        headerName: 'EU Fuel Slip \n(t,mrv) Remarks',
        type: 'string',
        align: 'right',
        headerAlign: 'right',
        minWidth: BIG_CELL_WIDTH,
        valueField: columns(
          'remarksColumns.calculations.ghgMrv.fuelSlippageByYear'
        ),
        visibility: multiYearDataVisibility,
      }),
      GridColumn({
        field: columns('calculations.ghgMrv.ghgByYear'),
        headerName: 'GHGmrv \n(tCO₂eq, TtW)',
        type: '{number,number}[]',
        align: 'right',
        headerAlign: 'right',
        minWidth: SMALL_CELL_WIDTH,
        valueField: columns('calculations.ghgMrv.ghgByYear'),
        noDecimals: 2,
        valueFormatter: (params) => ghgGetStandardKvpColumn(params),
        renderCell: (params: any) =>
          renderCellWithToolTip(
            params,
            'GHGmrv',
            params.row?.calculations?.ghgMrv?.ghgByYear
          ),
      }),
      GridColumn({
        field: columns('remarksColumns.calculations.ghgMrv.ghgByYear'),
        headerName: 'GHGmrv \n(tCO₂eq, TtW) Remarks',
        type: 'string',
        align: 'right',
        headerAlign: 'right',
        minWidth: BIG_CELL_WIDTH,
        valueField: columns('remarksColumns.calculations.ghgMrv.ghgByYear'),
        visibility: multiYearDataVisibility,
      })
    );
  }
  ret.push(
    GridColumn({
      field: columns('calculations.eua.liability.fieldValue'),
      headerName: 'EUA \nLiability',
      type: '{number,number}[]',
      align: 'right',
      headerAlign: 'right',
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 0,
      renderHeader: euaLiabilitiesTooltipHeader,
      renderCell: (params: any) => {
        const row = params.row as IVoyageComparisonLegDomainWithStatus;
        const value = row?.calculations?.eua?.liability?.fieldValue;
        const euaByYearValues = row?.calculations?.eua?.euaByYear;
        const euaLiabilitiesTooltip = euaByYearValues?.map(
          ({ liability, year }, index) => (
            <React.Fragment key={index}>
              <Box>
                EUA ({year}):{' '}
                {formatNumber(
                  parseFloat(liability?.fieldValue?.toString() ?? '0'),
                  2
                )}
              </Box>
            </React.Fragment>
          )
        );
        return (
          <Tooltip
            title={
              crossingYearsFeatureFlagEnabled
                ? euaLiabilitiesTooltip
                : euaLiabilityDisplayValue(value, 2)
            }
          >
            <span className='MuiDataGrid-cellContent pointer'>
              {euaLiabilityDisplayValue(value, 0)}
            </span>
          </Tooltip>
        );
      },
      valueField: columns('calculations.eua.liability.fieldValue'),
      isNACheck: (params) => !params.row.calculations.eua.liability.fieldValue,
      valueFormatter: (params: any) => euaExcelDisplayValue(params),
    }),
    GridColumn({
      field: columns('remarksColumns.calculations.eua.liability'),
      headerName: 'EUA \nLiability Remarks',
      type: 'string',
      align: 'right',
      headerAlign: 'right',
      minWidth: BIG_CELL_WIDTH,
      valueField: columns('remarksColumns.calculations.eua.liability'),
      visibility: multiYearDataVisibility,
    }),
    GridColumn({
      field: columns('calculations.fuelEU.wellToTank.fieldValue'),
      headerName: `GHG Intensity \n(${TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ_PER_MEGAJOULE},\nWtT)`,
      headerClassName: GridCustomHeaderClass.BorderLeft,
      align: 'right',
      headerAlign: 'right',
      minWidth: SMALL_CELL_WIDTH,
      type: '{number,number}[]',
      valueField: columns('calculations.fuelEU.wellToTank.fieldValue'),
      noDecimals: 2,
      renderCell: (
        params: GridCellParams<
          IVoyageComparisonLegDomain,
          any,
          any,
          GridTreeNodeWithRender
        >
      ) =>
        renderFuelEUToolTip(
          params,
          `GHG Intensity \n(${TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ_PER_MEGAJOULE},\nWtT)`,
          'wellToTank',
          undefined,
          (value) => formatNumber(value, 2)
        ),
      visibility: vesselOverviewGhgIntensityWttColumnVoyageLegGrid
        ? 'visible'
        : 'hidden',
    }),
    GridColumn({
      field: columns('remarksColumns.calculations.fuelEU.wellToTank'),
      headerName: `GHG Intensity \n(${TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ_PER_MEGAJOULE},\nWtT) Remarks`,
      headerClassName: GridCustomHeaderClass.BorderLeft,
      align: 'right',
      headerAlign: 'right',
      minWidth: BIG_CELL_WIDTH,
      type: 'string',
      valueField: columns('remarksColumns.calculations.fuelEU.wellToTank'),
      visibility: multiYearDataVisibility,
    }),
    GridColumn({
      field: columns('calculations.fuelEU.tankToWake.fieldValue'),
      headerName: `GHG Intensity \n(${TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ_PER_MEGAJOULE},\nTtW)`,
      align: 'right',
      headerAlign: 'right',
      minWidth: SMALL_CELL_WIDTH,
      type: 'number',
      valueField: columns('calculations.fuelEU.tankToWake.fieldValue'),
      noDecimals: 2,
      renderCell: (params) =>
        renderFuelEUToolTip(
          params,
          `GHG Intensity \n(${TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ_PER_MEGAJOULE},\nTtW)`,
          'tankToWake',
          undefined,
          (value) => formatNumber(value, 2)
        ),
      visibility: vesselOverviewGhgIntensityTtwColumnVoyageLegGrid
        ? 'visible'
        : 'hidden',
    }),
    GridColumn({
      field: columns('remarksColumns.calculations.fuelEU.tankToWake'),
      headerName: `GHG Intensity \n(${TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ_PER_MEGAJOULE},\nTtW) Remarks`,
      align: 'right',
      headerAlign: 'right',
      minWidth: BIG_CELL_WIDTH,
      type: 'string',
      valueField: columns('remarksColumns.calculations.fuelEU.tankToWake'),
      visibility: multiYearDataVisibility,
    }),
    GridColumn({
      field: columns('calculations.fuelEU.wellToWake.fieldValue'),
      headerName: `GHG Intensity \n(${TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ_PER_MEGAJOULE},\nWtW)`,
      align: 'right',
      headerAlign: 'right',
      minWidth: SMALL_CELL_WIDTH,
      type: '{number,number}[]',
      valueField: columns('calculations.fuelEU.wellToWake.fieldValue'),
      noDecimals: 2,
      renderCell: (
        params: GridCellParams<
          IVoyageComparisonLegDomain,
          any,
          any,
          GridTreeNodeWithRender
        >
      ) =>
        renderFuelEUToolTip(
          params,
          `GHG Intensity \n(${TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ_PER_MEGAJOULE},\nWtW)`,
          'wellToWake',
          params.row?.calculations?.fuelEU?.wellToWakeStatus,
          (value) => formatNumber(value, 2)
        ),
      visibility: vesselOverviewGhgIntensityWtwColumnVoyageLegGrid
        ? 'visible'
        : 'hidden',
    }),
    GridColumn({
      field: columns('remarksColumns.calculations.fuelEU.wellToWake'),
      headerName: `GHG Intensity \n(${TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ_PER_MEGAJOULE},\nWtW) Remarks`,
      align: 'right',
      headerAlign: 'right',
      minWidth: BIG_CELL_WIDTH,
      type: 'string',
      valueField: columns('remarksColumns.calculations.fuelEU.wellToWake'),
      visibility: multiYearDataVisibility,
    }),
    GridColumn({
      field: columns('calculations.fuelEU.complianceBalance.fieldValue'),
      headerName: `Compliance \nBalance \n(${TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ})`,
      align: 'right',
      headerAlign: 'right',
      minWidth: SMALL_CELL_WIDTH,
      type: '{number,number}[]',
      valueField: columns('calculations.fuelEU.complianceBalance.fieldValue'),
      noDecimals: 2,
      renderCell: (params) =>
        renderFuelEUToolTip(
          params,
          `Compliance \nBalance \n(${TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ})`,
          'complianceBalance',
          undefined,
          (value) => formatNumber(value, 2)
        ),
      avoidExcelReformat: true,
      visibility: vesselOverviewComplianceBalanceColumnVoyageLegGrid
        ? 'visible'
        : 'hidden',
    }),
    GridColumn({
      field: columns('remarksColumns.calculations.fuelEU.complianceBalance'),
      headerName: `Compliance \nBalance \n(${TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ}) Remarks`,
      align: 'right',
      headerAlign: 'right',
      minWidth: BIG_CELL_WIDTH,
      type: 'string',
      valueField: columns(
        'remarksColumns.calculations.fuelEU.complianceBalance'
      ),
      visibility: multiYearDataVisibility,
    }),
    GridColumn({
      field: columns('calculations.fuelEU.fuelPenalty.fieldValue'),
      headerName: `FuelEU \nPenalty \n(${TEXT.CURRENCY.EURO})`,
      align: 'right',
      headerAlign: 'right',
      minWidth: SMALL_CELL_WIDTH,
      type: '{number,number}[]',
      valueField: columns('calculations.fuelEU.fuelPenalty.fieldValue'),
      noDecimals: 2,
      renderCell: (params) =>
        renderFuelEUToolTip(
          params,
          `FuelEU \nPenalty \n(${TEXT.CURRENCY.EURO})`,
          'fuelPenalty',
          undefined,
          formatLargeNumber
        ),
      visibility: vesselOverviewFuelEuPenaltyColumnVoyageLegGrid
        ? 'visible'
        : 'hidden',
    }),
    GridColumn({
      field: columns('remarksColumns.calculations.fuelEU.fuelPenalty'),
      headerName: `FuelEU \nPenalty \n(${TEXT.CURRENCY.EURO}) Remarks`,
      align: 'right',
      headerAlign: 'right',
      minWidth: BIG_CELL_WIDTH,
      type: 'string',
      valueField: columns('remarksColumns.calculations.fuelEU.fuelPenalty'),
      visibility: multiYearDataVisibility,
    }),
    GridColumn({
      field: columns('calculations.fuelEU.onShorePowerSupply.fieldValue'),
      headerName: 'Onshore \nPower \nSupply',
      align: 'right',
      headerAlign: 'right',
      minWidth: SMALL_CELL_WIDTH,
      type: 'string',
      valueField: columns('calculations.fuelEU.onShorePowerSupply.fieldValue'),
      renderCell: (params) =>
        renderFuelEUToolTip(
          params,
          'Onshore \nPower \nSupply',
          'onShorePowerSupply'
        ),
      visibility: vesselOverviewOnshorePowerSupplyColumnVoyageLegGrid
        ? 'visible'
        : 'hidden',
    }),
    GridColumn({
      field: columns('remarksColumns.calculations.fuelEU.onShorePowerSupply'),
      headerName: 'Onshore \nPower \nSupply Remarks',
      align: 'right',
      headerAlign: 'right',
      minWidth: BIG_CELL_WIDTH,
      type: 'string',
      valueField: columns(
        'remarksColumns.calculations.fuelEU.onShorePowerSupply'
      ),
      visibility: multiYearDataVisibility,
    })
  );

  return ret;
}
function renderEmissionCell(
  params: any,
  emission: string,
  value: number,
  display: string,
  forExport = false
) {
  const row = params.row as IVoyageComparisonLegDomainWithStatus;
  const yearlyValues = row?.calculations?.ghgMrv?.emissionsByYear
    ?.filter((x) => x.key.emission === emission)
    .map((x) => ({ key: x.key.year, value: x.value }));

  return renderCellWithToolTip(params, display, yearlyValues!, value);
}

function renderEmissionText(
  row: IVoyageComparisonLegDomain,
  emission: string,
  display: string
) {
  const yearlyValues = row?.calculations?.ghgMrv?.emissionsByYear
    ?.filter((x) => x.key.emission === emission)
    .map((x) => ({ key: x.key.year, value: x.value }));
  return !yearlyValues || yearlyValues?.length <= 1
    ? ''
    : yearlyValues
        ?.map(
          ({ key, value }) =>
            `${display} (${key}): ${formatNumber(
              parseFloat(value?.toString() ?? '0'),
              2
            )}`
        )
        .join('\n');
}

function renderRemarksText(
  display: string,
  yearlyValues: { value: number; key: number }[] | undefined
) {
  return !yearlyValues || yearlyValues?.length <= 1
    ? ''
    : yearlyValues
        ?.map(
          ({ key, value }) =>
            `${display} (${key}): ${formatNumber(
              parseFloat(value?.toString() ?? '0'),
              2
            )}`
        )
        .join('\n');
}

function renderEuaByYearText(
  row: IVoyageComparisonLegDomain,
  title: string,
  field: keyof IEuaByYear
) {
  const yearlyValues = row.calculations.eua.euaByYear?.map((x: any) => ({
    key: x.year,
    value: x[field].fieldValue,
  }));
  return !yearlyValues || yearlyValues?.length <= 1
    ? ''
    : yearlyValues
        ?.map(
          ({ key, value }) =>
            `${title} (${key}): ${formatNumber(
              parseFloat(value?.toString() ?? '0'),
              2
            )}`
        )
        .join('\n');
}

function renderCellWithToolTip(
  params: any,
  name: string,
  yearlyValues: { value: number; key: number }[],
  value: number | null = null
) {
  const toolTip = yearlyValues?.map(({ key, value }) => (
    <React.Fragment key={key}>
      <Box>
        {name} ({key}): {formatNumber(parseFloat(value?.toString() ?? '0'), 2)}
      </Box>
    </React.Fragment>
  ));
  const displayValue =
    value ?? yearlyValues?.map((v) => v.value).reduce((a, b) => a + b, 0);
  return displayValue >= 0 && yearlyValues.length > 0 ? (
    <Tooltip title={toolTip}>
      <span className='MuiDataGrid-cellContent pointer'>
        {euaLiabilityDisplayValue(displayValue, 2)}
      </span>
    </Tooltip>
  ) : (
    <span>{euaLiabilityDisplayValue(value, 2)}</span>
  );
}

function renderFuelEUToolTip(
  params: GridCellParams<
    IVoyageComparisonLegDomain,
    any,
    any,
    GridTreeNodeWithRender
  >,
  title: string,
  field: keyof IFuelEU,
  status?: Status,
  formatValue?: (value: any) => string
) {
  const yearlyValues = params.row.calculations.fuelEU.fuelEuByYear?.map(
    (x: any) => ({
      key: x.year,
      value: x[field].fieldValue,
    })
  );
  const dataField = params.row.calculations.fuelEU[field];
  let fieldValue = isIVoyageComparisonField(dataField)
    ? dataField?.fieldValue
    : (dataField as Status);
  if (formatValue) {
    fieldValue = formatValue(fieldValue);
  }
  if (status && yearlyValues?.length === 1) {
    return (
      <RAGDisplay
        value={
          <Tooltip
            title={yearlyValues?.map(({ key, value }) => (
              <Box key={key}>
                {title} ({key}): {formatNumber(value ?? 0, 2)}
              </Box>
            ))}
          >
            <span className='MuiDataGrid-cellContent pointer'>
              {fieldValue?.toString()}
            </span>
          </Tooltip>
        }
        status={status}
      />
    );
  }
  if (fieldValue === dashes && yearlyValues && yearlyValues?.length <= 1) {
    return <span className='MuiDataGrid-cellContent'>{fieldValue}</span>;
  }
  return (
    <Tooltip
      title={yearlyValues?.map(({ key, value }) => (
        <Box key={key}>
          {title} ({key}): {formatNumber(value ?? 0, 2)}
        </Box>
      ))}
    >
      <span className='MuiDataGrid-cellContent pointer'>
        {fieldValue?.toString()}
      </span>
    </Tooltip>
  );
}

function renderFuelEUText(
  row: IVoyageComparisonLegDomain,
  title: string,
  field: keyof IFuelEU
) {
  const yearlyValues = row.calculations.fuelEU.fuelEuByYear?.map((x: any) => ({
    key: x.year,
    value: x[field].fieldValue,
  }));

  return !yearlyValues || yearlyValues?.length <= 1
    ? ''
    : yearlyValues
        ?.map(
          ({ key, value }) =>
            `${title} (${key}): ${formatNumber(
              parseFloat(value?.toString() ?? '0'),
              2
            )}`
        )
        .join('\n');
}

function isIVoyageComparisonField(value: any): value is IVoyageComparisonField {
  return value?.fieldValue !== undefined;
}

function isMultiyearData(data: any) {
  if (data && data.legs && data.legs.length > 1) {
    const firstVoyage = data.legs[0];
    const lastVoyage = data.legs[data.legs.length - 1];
    return (
      (firstVoyage?.departureDate.fieldValue as UTCDate)?.year !==
      (lastVoyage?.departureDate.fieldValue as UTCDate)?.year
    );
  } else {
    return false;
  }
}

function euaLiabilityDisplayValue(value: any, decimal: number) {
  if (value === null || value === undefined) {
    return NA_VALUE_INDICATOR;
  } else if (stringToNumber(value?.toString() ?? '0') === -1) {
    return TEXT.ERROR_MESSAGES.EMPTY_DASHES;
  }
  return formatNumber(value, decimal);
}

export function ghgMrvEmissionTypeForCol(
  params: GridValueFormatterParams<{ key: EmissionKey; value: number }[]>,
  emissionType: string
) {
  const fieldValue = params.value;
  if (fieldValue != null) {
    const items = fieldValue.filter(
      (x: { key: EmissionKey }) => x.key.emission === emissionType
    );
    return items
      .reduce(
        (accumulator: number, object: { key: EmissionKey; value: number }) =>
          accumulator + object.value,
        0
      )
      .toFixed(2);
  }
  return TEXT.ERROR_MESSAGES.EMPTY_DASHES;
}
export function ghgGetStandardKvpColumn(
  params: GridValueFormatterParams<{ key: number; value: number }[]>
) {
  const fieldValues = params.value;
  if (Array.isArray(fieldValues) && fieldValues != null) {
    const sum = fieldValues.reduce(
      (accumulator: number, object: { key: number; value: number }) =>
        accumulator + object.value,
      0
    );
    return formatNumber(sum, 2);
  }
  return TEXT.ERROR_MESSAGES.EMPTY_DASHES;
}
export function euaExcelDisplayValue(
  params: GridValueFormatterParams<any>,
  noDecimals = 0
) {
  const fieldValue = params.value;
  const fieldValueAsNumber = stringToNumber(fieldValue?.toString() ?? '0');
  if (fieldValueAsNumber === -1) {
    return TEXT.ERROR_MESSAGES.EMPTY_DASHES;
  }
  return formatNumber(fieldValue, noDecimals);
}

export function VoyageComparison() {
  const { utcDateRange } = useContext(NavBarContext);
  const { id } = useParams();
  const { data, loading, error } = useGetVoyageComparison(id, utcDateRange);

  const isMultiYearData = isMultiyearData(data);

  const { featureFlags } = useFeatureToggleContext();
  // Pass featureFlags to getColumnsDefinition
  const columns = getColumnsDefinition(featureFlags, isMultiYearData);

  const translatedData = data?.legs?.map(
    (x): IVoyageComparisonLegDomainWithStatus => {
      return {
        ...x,
        realCalculations: {
          cii: {
            value: x.calculations.cii.value.displayValue,
            status: x.calculations.cii.status,
          },
          eeoiDeviation: {
            value: x.calculations.eeoi.deviation.displayValue,
            status: x.calculations.eeoi.status,
          },
        },
        //todo assign values
        remarksColumns: {
          calculations: {
            eua: {
              euCo2: renderEmissionText(
                x,
                EmissionsType.CO2,
                EmissionsTypeDisplay.CO2
              ),
              liability: renderEuaByYearText(x, 'EUA', 'liability'),
            },
            ghgMrv: {
              totalCh4: renderEmissionText(
                x,
                EmissionsType.CH4,
                EmissionsTypeDisplay.CH4
              ),
              totalN2o: renderEmissionText(
                x,
                EmissionsType.N2O,
                EmissionsTypeDisplay.N2O
              ),
              fuelSlippageByYear: renderRemarksText(
                'Fuel Slip',
                x.calculations?.ghgMrv?.fuelSlippageByYear
              ),
              ghgByYear: renderRemarksText(
                'GHGmrv',
                x.calculations?.ghgMrv?.ghgByYear
              ),
            },
            fuelEU: {
              wellToTank: renderFuelEUText(
                x,
                `GHG Intensity (${TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ_PER_MEGAJOULE},WtT)`,
                'wellToTank'
              ),
              wellToWake: renderFuelEUText(
                x,
                `GHG Intensity (${TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ_PER_MEGAJOULE},WtW)`,
                'wellToWake'
              ),
              tankToWake: renderFuelEUText(
                x,
                `GHG Intensity (${TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ_PER_MEGAJOULE},TtW)`,
                'tankToWake'
              ),
              complianceBalance: renderFuelEUText(
                x,
                `Compliance Balance (${TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ})`,
                'complianceBalance'
              ),
              fuelPenalty: renderFuelEUText(
                x,
                `FuelEU Penalty (${TEXT.CURRENCY.EURO}) Remarks`,
                'fuelPenalty'
              ),
              onShorePowerSupply: renderFuelEUText(
                x,
                'Onshore Power Supply',
                'onShorePowerSupply'
              ),
            },
          },
        },
      };
    }
  );

  const extractKeysForFiltering = ({
    calculations: { cii, eeoi, aer },
  }: IVoyageComparisonLegDomain) => ({
    cii,
    eeoi,
    aer,
  });
  const filterResult = useFilter<IVoyageComparisonLegDomain>(
    translatedData ?? [],
    extractKeysForFiltering
  );
  return (
    <CardContainer title={vesselPageTitles.voyageLegsComparison}>
      <RAGFilter
        activeFilters={filterResult.activeFilters}
        handleToggle={filterResult.handleFilter}
      ></RAGFilter>
      <DataGridTable
        name={vesselPageTitles.voyageLegsComparisonId}
        rows={filterResult.filteredData}
        columns={columns}
        loading={loading}
        error={error}
      ></DataGridTable>
    </CardContainer>
  );
}
