import { DateRange, UTCDate } from 'shared/utils/date-utc-helper';
import {
  AxisConfig,
  BarSeriesType,
  ChartsItemContentProps,
  ChartsXAxisProps,
  ScatterSeriesType,
  ScatterValueType,
} from '@mui/x-charts-pro';
import { MuiChartContainerWithLegend } from 'shared/components/mui-chart/container.component';
import { CardContainer } from 'shared/components/navigation/cardContainer.component';
import { TEXT } from 'shared/constants/text';
import { Box } from '@mui/system';
import { nameof } from 'shared/components/datagrid';
import { useGetFleetFuelEuGhgIntensityChart } from 'routes/environmental-monitor/services/fleet.service';
import { useEffect, useState } from 'react';
import { vesselPageTitles } from 'routes/environmental-monitor/view-models/titles.viewModel';
import { FixedTimeline } from 'assets/fixed-timeline';
import { useParams } from 'react-router-dom';
import { useGetVesselById } from 'routes/environmental-monitor/services/vessel.service';
import FuelEuGhGFuelExploreData from './fuel-eu-ghg-intensity-usage-trend-explore-data.component';
import { DataToggleButton } from 'routes/environmental-monitor/view-models/data-toggle-button.viewModel';
import { FuelEuGhgIntensityTrendChartKeyItems } from 'routes/environmental-monitor/view-models/fuel-eu-ghg-intensity-usage-trend-chart.viewModel';
import { hexToRgba } from 'shared/utils/colors-utils';
import {
  fuelEuGhgIntensityUsageTooltip,
  fuelEuGhgIntensityUsageTooltipTitle,
} from '../../tooltip/fuel-eu-ghg-intensity-usage-trend-chart-tooltip.component';
import { ForecastLineSeriesType } from 'shared/components/mui-chart/components/line-forecast.component';
import {
  AreaBand,
  AreaDefinition,
} from 'shared/components/mui-chart/background/draw-background.component';
import { theme } from 'styles/theme';
import { ChartLegend } from '../eua/eua-ratings-mui-chart.component';
import {
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { SelectStyles } from '../../fleet-cii-ratings/cii-ratings-historical-data-selection.component';
import {
  rangeValues,
  TrendChartDataset,
} from 'routes/environmental-monitor/view-models/trend-chart.viewModel';
import {
  DataPointWithUTCDate,
  FuelEUGhgIntensityChartProps,
} from 'routes/environmental-monitor/models/fleet.model';
import { ErrorComponent } from 'shared/components/error/error.component';

const chartProps = nameof<FuelEUGhgIntensityChartProps>;

const dateFormatter = (date: Date, showOnlyYear: boolean) => {
  if (date === undefined) return '';

  const utcDate = new UTCDate(date);
  const currentYear = new UTCDate().date?.getUTCFullYear() ?? 0;
  const dateYear = utcDate.date?.getUTCFullYear() ?? 0;

  if (showOnlyYear || dateYear > currentYear) {
    return dateYear.toString();
  }
  return new UTCDate(date).formatMY();
};

const yAxisLeftTitle = `GHG Intensity (${TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ_PER_MEGAJOULE})`;
const yAxisRightTitle = `Fuel Consumed (${TEXT.UNIT_MEASUREMENT.METRIC_TONNES})`;
const RegulationsDeadlineYear = 2050;

export const FuelEUGhgIntensityAndUsageTrendChart = () => {
  // we don't need to use the date range from the navbar context
  const [chartDateRange, setChartDateRange] = useState<rangeValues>('0');
  const startDate = new UTCDate().startOfYear;
  const noYearsFromDropdown = Number(chartDateRange);
  const currentYear = new UTCDate().date?.getUTCFullYear() ?? 0;

  // this is the number of years to add to the current year, for all we display all the years up to 2050
  const noExtraYears =
    noYearsFromDropdown === -1
      ? RegulationsDeadlineYear - currentYear
      : noYearsFromDropdown;
  const endDate = UTCDate.create(currentYear + noExtraYears, 0, 1).endOfYear; // Date range for the current year;
  const utcDateRange = new DateRange(startDate, endDate); // Date range for the current year

  // TODO: this should be the vessel IMO number, right now it uses the vessel ID
  const { id } = useParams();
  const vesselsResponse = useGetVesselById(id);
  const vesselImo = vesselsResponse.data?.imoNumber ?? '';
  const fleetFuelEuGhgIntensityChart = useGetFleetFuelEuGhgIntensityChart(
    utcDateRange,
    [vesselImo]
  );

  const [chartDataset, setChartDataset] = useState<
    FuelEUGhgIntensityChartProps[]
  >([]);

  const [dataToggleButtons, setDataToggleButtons] = useState<
    DataToggleButton[]
  >([]);

  const [seriesDate, setSeriesDate] = useState<
    (ForecastLineSeriesType | BarSeriesType | ScatterSeriesType)[]
  >([]);

  const [areas, setAreas] = useState<Array<AreaDefinition>>([]);

  const tempChartDataset = fleetFuelEuGhgIntensityChart.data;
  const loading = fleetFuelEuGhgIntensityChart.loading;
  const error = fleetFuelEuGhgIntensityChart.error;

  useEffect(() => {
    if (loading === false && tempChartDataset) {
      const myData = new TrendChartDataset(tempChartDataset, chartDateRange);
      const dataset = myData.fullDataset;
      const bandAreas = myData.getBandAreas();
      setChartDataset(dataset);
      setAreas(bandAreas);

      const newToggleButtons: DataToggleButton[] = [];

      dataset[0] &&
        Object.keys(dataset[0]).forEach((key) => {
          if (
            key.endsWith('Fuel') &&
            dataset.some((item) => {
              const value = item[key as keyof FuelEUGhgIntensityChartProps];
              return typeof value === 'number' && value > 0;
            })
          ) {
            const buttonConfig =
              FuelEuGhgIntensityTrendChartKeyItems[
                key as keyof typeof FuelEuGhgIntensityTrendChartKeyItems
              ];
            newToggleButtons.push({
              key: buttonConfig.key ?? '',
              title: buttonConfig.name,
              isSelected: false,
              circleFill: buttonConfig.fill,
              selectedColor: hexToRgba(buttonConfig.fill),
              name: buttonConfig.name,
            });
          }
        });

      setDataToggleButtons(newToggleButtons);
    }
  }, [loading, chartDateRange]);

  const handleToggleButtonClick = (key: string) => {
    setDataToggleButtons((prevButtons) =>
      prevButtons.map((button) =>
        button.key === key
          ? { ...button, isSelected: !button.isSelected }
          : button
      )
    );
  };
  const bands: AreaBand[] = [
    {
      type: 'area',
      axisId: 'band-axis',
      showLine: true,
      areas: areas,
      lineColor: FuelEuGhgIntensityTrendChartKeyItems.ghgIntensityTarget.fill,
      backgroundColor: theme.colors?.eRating,
    },
  ];

  const calculatedGap = chartDataset.length > 12 ? 0.8 : 0.9;
  const intensityTargetDataPoints: ScatterValueType[] = chartDataset.map(
    (item, index) => ({
      x: item?.date?.getTime() ?? 0,
      y: item.intensityTarget ?? 0,
      id: index,
    })
  );

  // update the series based on the selected data toggle buttons
  useEffect(() => {
    const myData = new TrendChartDataset(tempChartDataset, chartDateRange);

    const newSeries: (
      | ForecastLineSeriesType
      | BarSeriesType
      | ScatterSeriesType
    )[] = [
      {
        id: chartProps('intensityTarget'),
        type: 'scatter',
        markerSize: 20,
        color: 'transparent',
        data: intensityTargetDataPoints,
      },
    ];

    if (tempChartDataset?.find((item) => item.intensity > 0)) {
      const limit = myData.getForecastStartTime();

      newSeries.push({
        id: chartProps('intensity'),
        type: 'line',
        dataKey: chartProps('intensity'),
        label: FuelEuGhgIntensityTrendChartKeyItems.ghgIntensity.name,
        highlightScope: { highlighted: 'item', faded: 'global' },
        color: FuelEuGhgIntensityTrendChartKeyItems.ghgIntensity.fill,
        showMark: ({ position }) => {
          // we need to show the mark for the current data, and not the forecast values
          if (typeof position === 'number') return true;
          if (position?.getTime() <= limit) return true;
          return false;
        },

        forecast: {
          limit: limit,
          sxAfter: { strokeDasharray: '10 5' },
        },
      });
    }

    dataToggleButtons.forEach((button) => {
      if (button.isSelected) {
        const dataKey = button.key;
        newSeries.push({
          type: 'bar',
          id: dataKey,
          dataKey: dataKey,
          label:
            FuelEuGhgIntensityTrendChartKeyItems[
              dataKey as keyof typeof FuelEuGhgIntensityTrendChartKeyItems
            ].name,
          color:
            FuelEuGhgIntensityTrendChartKeyItems[
              dataKey as keyof typeof FuelEuGhgIntensityTrendChartKeyItems
            ].fill,
          stack: 'fuels',
          yAxisId: 'right',
        });
      }
    });

    setSeriesDate(newSeries);
  }, [dataToggleButtons]);

  const intensityTargetLegend: ChartLegend = {
    label: 'GHG Intensity Target',
    color: FuelEuGhgIntensityTrendChartKeyItems.ghgIntensityTarget.fill,
  };

  const handleChange = (event: SelectChangeEvent) => {
    setChartDateRange(event.target.value as rangeValues);
  };

  // get the max value for the left y-axis
  const maxIntensity = Math.max(...chartDataset.map((item) => item.intensity));
  const maxIntensityTarget = Math.max(
    ...chartDataset.map((item) => item.intensityTarget)
  );
  const maxLeftYValue = Math.max(maxIntensity, maxIntensityTarget) + 10; // add buffer to the max value

  const dataPointIsComplete = (item: DataPointWithUTCDate) =>
    item.intensity > 0 ||
    item.intensityTarget > 0 ||
    item.bioFuel > 0 ||
    item.eFuel > 0 ||
    item.fossilFuel > 0;

  const hasData =
    tempChartDataset &&
    tempChartDataset.length > 0 &&
    tempChartDataset.find((item) => dataPointIsComplete(item));

  if (error || !hasData) {
    return (
      <CardContainer
        title={vesselPageTitles.vesselGhgIntensityAndFuelTrend}
        icon={<FixedTimeline sx={{ margin: '5px 0px 0px 15px' }} />}
      >
        <ErrorComponent error={error} empty={!hasData} />
      </CardContainer>
    );
  }

  return (
    <CardContainer
      title={vesselPageTitles.vesselGhgIntensityAndFuelTrend}
      icon={<FixedTimeline sx={{ margin: '5px 0px 0px 15px' }} />}
    >
      <Box component={'section'} sx={{ pl: 1, mb: 1, position: 'relative' }}>
        <RangePicker value={chartDateRange} handleChange={handleChange} />
        <MuiChartContainerWithLegend
          background={{ bands: bands }}
          margin={{ right: 70 }}
          dataset={chartDataset}
          series={seriesDate}
          loading={loading}
          error={fleetFuelEuGhgIntensityChart.error}
          tooltip={{
            trigger: 'item',
            title: (params) =>
              fuelEuGhgIntensityUsageTooltipTitle(
                params as ChartsItemContentProps<any>,
                chartDataset
              ),
            content: (params) =>
              fuelEuGhgIntensityUsageTooltip(
                params as ChartsItemContentProps<any>,
                chartDataset
              ),
          }}
          xAxis={[
            {
              id: 'band-axis',
              dataKey: chartProps('date'),
              tickNumber: chartDataset.length,
              tickPlacement: 'middle',
              scaleType: 'band',
              tickLabelStyle: { angle: -35 },
              valueFormatter: (value) =>
                dateFormatter(value, chartDateRange === '-1'),
              categoryGapRatio: calculatedGap,
            } as AxisConfig<'band', any, ChartsXAxisProps>,
          ]}
          yAxis={[
            {
              label: yAxisLeftTitle,
              min: 0,
              max: maxLeftYValue,
              position: 'left',
              id: 'left',
            },
            { label: yAxisRightTitle, min: 0, position: 'right', id: 'right' },
          ]}
          additionalLegend={[intensityTargetLegend]}
        ></MuiChartContainerWithLegend>
        <Box
          sx={{
            ml: 4,
            mr: 4,
            mb: 5,
          }}
        >
          <FuelEuGhGFuelExploreData
            onClick={handleToggleButtonClick}
            dataToggles={dataToggleButtons}
          />
        </Box>
      </Box>
    </CardContainer>
  );
};

const RangePicker = (props: {
  value: rangeValues;
  handleChange: (event: SelectChangeEvent) => void;
}) => {
  return (
    <Box sx={{ position: 'absolute', top: '0', right: '70px' }}>
      <Select
        data-testid='select-box'
        sx={{ ...SelectStyles }}
        input={<OutlinedInput />}
        label='Select Range'
        onChange={props.handleChange}
        value={props.value}
      >
        <MenuItem value='0'>Current Year</MenuItem>
        <MenuItem value='3'>3 Years</MenuItem>
        <MenuItem value='5'>5 Years </MenuItem>
        <MenuItem value='-1'>All</MenuItem>
      </Select>
    </Box>
  );
};
