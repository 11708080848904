import { Box, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { KpiCardError } from 'shared/components/error/kpi-card-error.component';
import { KpiCardSkeleton } from 'shared/components/kpi-card-skeleton/kpi-card-skeleton.component';
import {
  customFooterType,
  KpiCardDivider,
  KpiCardGridContainer,
  RenderKPIGroup,
  StatusKpiCardProps,
} from 'shared/components/status-kpi-card/status-kpi-card.component';
import { ExcelExportContext } from 'shared/models/excel-export.context.model';
import { formatNumber } from 'shared/utils/float-utils';
import { TEXT } from 'shared/constants/text';
import { typography } from 'styles/typography';
import { VesselKPIDataMapper } from 'routes/environmental-monitor/mappers/vessel-kpi-data.mapper';
import { useFeatureToggleContext } from 'shared/components/contexts/featureToggleProvider.component';
import { EUAChart as EUAMuiChart } from './eua/eua-ratings-mui-chart.component';
import { Status } from 'routes/environmental-monitor/models/gql/vessel-kpi-gql.model';
import { FuelEUGhgIntensityAndUsageTrendChart } from './fuel-eu-ghg-and-usage-trend/fuel-eu-ghg-intensity-usage-trend-chart.component';
import { FEATURE_FLAG } from 'shared/constants/feature-flag';
import { ExportableSections } from 'shared/utils/valid-sections';
import { NA_VALUE_INDICATOR } from 'shared/components/datagrid';
import { VesselKpiDomain } from 'routes/environmental-monitor/models/vessel-kpi.model';
import { ServiceResult } from 'shared/models/serviceResult.model';
import { flattenYearlyStatusKpiCards } from 'shared/utils/export/excel';
import { NavBarContext } from 'shared/models/navBar.context.model';

export const VesselEUkpi = ({
  vesselKpiDomain,
}: {
  readonly vesselKpiDomain?: ServiceResult<VesselKpiDomain> | undefined;
}) => {
  const { utcDateRange } = useContext(NavBarContext);
  const data = vesselKpiDomain?.data;
  const loading = vesselKpiDomain?.loading;
  const error = vesselKpiDomain?.error;

  // Access feature flags from the context
  const { featureFlags } = useFeatureToggleContext();

  // Check feature flags directly from the context
  const ghgMrvGhgKpiStoryEnabled =
    featureFlags[FEATURE_FLAG.VESSELOVERVIEW_KPIS_GHGKPI];
  const ghgMrvGhgKpiSummaryStoryEnabled =
    featureFlags[FEATURE_FLAG.VESSELOVERVIEW_KPIS_GHGKPI_SUMMARY];

  const ghgMrvPart2FeatureEnabled = featureFlags[FEATURE_FLAG.MRV_PART_2];
  const fuelEuGhgTrendChartFeatureEnabled =
    featureFlags[FEATURE_FLAG.FUEL_EU_GHG_TREND_CHART];
  const wellToWakeSummaryKpiCardFeatureEnabled =
    featureFlags[FEATURE_FLAG.VESSEL_OVERVIEW_WELL_TO_WAKE_SUMMARY_KPI_CARD];
  const fuelEuPenaltyAgainstFleetKpiCardFeatureEnabled =
    featureFlags[
      FEATURE_FLAG.VESSEL_OVERVIEW_FUEL_EU_PENALTY_AGAINST_FLEET_KPI_CARD
    ];
  const vesselGhgIntensityKpiCardEnabled =
    featureFlags[FEATURE_FLAG.VESSEL_OVERVIEW_GHG_INTENSITY_KPI_CARD];
  const section = ExportableSections['EU-Overview'];
  // TODO: Move to view model
  const euaPlannedValue =
    parseInt(data?.euaAgainstPlanned?.tertiaryValue ?? '') || null;
  const hasPlannedValue: boolean = (euaPlannedValue ?? 0) > 0;

  if (data && !hasPlannedValue) {
    data.euaAgainstPlanned.primaryValue = formatNumber(
      data?.euaAgainstPlanned?.secondaryValue,
      0
    );
  }

  const EuaAgainstPlannedProps: StatusKpiCardProps = {
    title: 'EU Allowances Against Planned',
    footerType: 'eua-planned',
    kpiData: data?.euaAgainstPlanned,
    sectionVisibility: { footer: true },
    isFeatureFlagEnabled: true,
  };

  const EuaAgainstFleetProps: StatusKpiCardProps = {
    title: 'EU Allowances Against Fleet',
    footerType: 'vessel-fleet',
    kpiData: data?.euaAgainstFleet,
    sectionVisibility: { footer: true },
    isFeatureFlagEnabled: true,
  };

  const EuaNoPlannedProps: StatusKpiCardProps = {
    title: 'EU Allowances',
    kpiData: {
      status:
        data?.euaAgainstPlanned?.primaryValue === NA_VALUE_INDICATOR
          ? 'unknown'
          : 'graph',
      primaryValue: formatNumber(data?.euaAgainstPlanned?.primaryValue),
    },
    isFeatureFlagEnabled: true,
  };

  const yearlyFuelEuKPIData =
    (data?.fuelEU == null || data?.fuelEU.length === 0
      ? [
          {
            year: utcDateRange?.endDate?.year as number,
            fuelEuGHGIntensity:
              VesselKPIDataMapper.toFuelEuVesselGHGIntensityDomain(undefined),
            complianceBalanceFleet:
              VesselKPIDataMapper.toComplianceBalanceFleetDomain(undefined),
            wellToTank: NA_VALUE_INDICATOR,
            tankToWake: NA_VALUE_INDICATOR,
            windRewardFactor: NA_VALUE_INDICATOR,
            complianceBalance: formatNumber(null, 2),
            fuelEUPenalty: VesselKPIDataMapper.toFuelEuPenaltyDomain(undefined),
          },
        ]
      : data.fuelEU
    ).map((fuelUE) => {
      const enabledKpis: StatusKpiCardProps[] = [];

      if (vesselGhgIntensityKpiCardEnabled) {
        const GHGIntensityProps: StatusKpiCardProps = {
          title: 'GHG Intensity',
          units: TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ_PER_MEGAJOULE,
          sectionVisibility: { footer: true },
          footerType: [
            {
              title: 'Compliance Balance',
              value: formatNumber(
                fuelUE?.fuelEuGHGIntensity?.secondaryValue,
                0
              ),
              unitLabel: TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ,
            },
            {
              title: `${TEXT.ENVIRONMENTAL_MONITOR.FUEL_EU.TITLE} Penalty`,
              value:
                TEXT.CURRENCY.EURO +
                ' ' +
                formatNumber(fuelUE?.fuelEuGHGIntensity?.tertiaryValue, 2),
              unitLabel: '',
            },
          ] as customFooterType,
          customExportDefinitions: [
            {
              title: `Compliance Balance (${TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ})`,
              value: formatNumber(
                fuelUE?.fuelEuGHGIntensity?.secondaryValue,
                0
              ),
              ignoreParentUnit: true,
            },
            {
              title: `${TEXT.ENVIRONMENTAL_MONITOR.FUEL_EU.TITLE} Penalty (${TEXT.CURRENCY.EURO})`,
              value: formatNumber(fuelUE?.fuelEuGHGIntensity?.tertiaryValue, 2),
              ignoreParentUnit: true,
            },
          ] as customFooterType,
          kpiData: fuelUE?.fuelEuGHGIntensity,
          isFeatureFlagEnabled: vesselGhgIntensityKpiCardEnabled ?? false,
        };
        enabledKpis.push(GHGIntensityProps);
      }

      if (wellToWakeSummaryKpiCardFeatureEnabled) {
        const WellToWakeCardProps: StatusKpiCardProps = {
          title: `Well to Wake (${TEXT.UNIT_MEASUREMENT.WELL_TO_WAKE}) Summary (${TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ_PER_MEGAJOULE})`,
          titleAlignment: 'center',
          footerType: [
            {
              title: 'Well to Tank',
              value: fuelUE?.wellToTank,
              unitLabel: '',
            },
            {
              title: 'Tank to Wake',
              value: fuelUE?.tankToWake,
              unitLabel: '',
            },
            {
              title: 'Wind Reward Factor',
              value: fuelUE?.windRewardFactor,
              unitLabel: '',
            },
          ] as customFooterType,
          customExportDefinitions: [
            {
              title: `Well to Tank (${TEXT.UNIT_MEASUREMENT.WELL_TO_TANK})`,
              value: formatNumber(fuelUE?.wellToTank, 2),
              unitLabel: '',
            },
            {
              title: `Tank to Wake (${TEXT.UNIT_MEASUREMENT.TANK_TO_WAKE})`,
              value: formatNumber(fuelUE?.tankToWake, 2),
              unitLabel: '',
            },
            {
              title: 'Wind Reward Factor',
              value: formatNumber(fuelUE?.windRewardFactor, 2),
              unitLabel: '',
            },
          ] as customFooterType,
          kpiData: {
            primaryValue: '',
            status:
              (fuelUE?.wellToTank &&
                fuelUE?.wellToTank !== NA_VALUE_INDICATOR) ||
              (fuelUE?.tankToWake &&
                fuelUE?.tankToWake !== NA_VALUE_INDICATOR) ||
              (fuelUE?.windRewardFactor &&
                fuelUE?.windRewardFactor !== NA_VALUE_INDICATOR)
                ? Status.graph
                : Status.none,
            secondaryValue: formatNumber(fuelUE?.wellToTank, 2),
            tertiaryValue: formatNumber(fuelUE?.tankToWake, 2),
            quaternaryValue: formatNumber(fuelUE?.windRewardFactor, 2),
          },
          sectionVisibility: { primaryValue: false, footer: true },
          isFeatureFlagEnabled: wellToWakeSummaryKpiCardFeatureEnabled ?? false,
        };
        enabledKpis.push(WellToWakeCardProps);
      }

      if (fuelEuPenaltyAgainstFleetKpiCardFeatureEnabled) {
        const FuelEUPenaltyProps: StatusKpiCardProps = {
          title: `${TEXT.ENVIRONMENTAL_MONITOR.FUEL_EU.TITLE} Penalty Against Fleet`,
          sectionVisibility: { footer: true },
          footerType: [
            {
              title: 'Fleet',
              value:
                TEXT.CURRENCY.EURO +
                ' ' +
                formatNumber(fuelUE?.fuelEUPenalty?.tertiaryValue, 2),
              unitLabel: '',
            },
            {
              title: 'Vessel',
              value:
                TEXT.CURRENCY.EURO +
                ' ' +
                formatNumber(fuelUE?.fuelEUPenalty?.secondaryValue, 2),
              unitLabel: '',
            },
          ] as customFooterType,
          customExportDefinitions: [
            {
              title: `Fleet (${TEXT.CURRENCY.EURO})`,
              value: formatNumber(fuelUE?.fuelEUPenalty?.tertiaryValue, 2),
              unitLabel: '',
            },
            {
              title: `Vessel (${TEXT.CURRENCY.EURO})`,
              value: formatNumber(fuelUE?.fuelEUPenalty?.secondaryValue, 2),
              unitLabel: '',
            },
          ] as customFooterType,
          kpiData: fuelUE?.fuelEUPenalty,
          isFeatureFlagEnabled:
            fuelEuPenaltyAgainstFleetKpiCardFeatureEnabled ?? false,
        };
        enabledKpis.push(FuelEUPenaltyProps);
      }

      return { year: fuelUE?.year, data: enabledKpis };
    }) ?? [];

  const GhgMrvEmissionProps: StatusKpiCardProps = {
    title: 'Greenhouse Gas (GHG) Emission',
    titleAlignment: 'center',
    footerType: (
      <Box sx={{ textAlign: 'center', width: '100%' }}>
        <Typography
          sx={{ fontSize: typography.fontSize['10'], lineHeight: '100%' }}
        >
          {formatNumber(data?.ghgMrvTotal, 2)}
        </Typography>
        <Box fontSize={'medium'} sx={{ color: 'gray' }}>
          tCO₂eq
        </Box>
        <Box sx={{ mt: 3 }}>MRV, Tank to Wake</Box>
      </Box>
    ),
    kpiData: {
      primaryValue: '',
      status: data?.ghgMrvTotal ? Status.graph : Status.none,
    },
    customExportDefinitions: [
      {
        title: `MRV, Tank to Wake (${TEXT.UNIT_MEASUREMENT.METRIC_TONNES_CO2_EQ})`,
        value: formatNumber(data?.ghgMrvTotal, 2),
        unitLabel: '',
      },
    ] as customFooterType,
    hideFooterDivider: true,
    sectionVisibility: { primaryValue: false, footer: true },
    isFeatureFlagEnabled: true,
  };

  const ghgMrvSummaryChartDomain =
    VesselKPIDataMapper.toGhgMrvSummaryChartDomain(data?.ghgMrvFuelEmissions);

  const ghgMrvSummaryDefinitions = [
    {
      title: `Carbon Dioxide (${TEXT.UNIT_MEASUREMENT.CO2})`,
      value: ghgMrvSummaryChartDomain.data.co2,
      unitLabel: '',
    },
    {
      title: `Methane (${TEXT.UNIT_MEASUREMENT.CH4})`,
      value: ghgMrvSummaryChartDomain.data.ch4,
      unitLabel: '',
    },
    {
      title: `Nitrous Oxide (${TEXT.UNIT_MEASUREMENT.N20})`,
      value: ghgMrvSummaryChartDomain.data.n2o,
      unitLabel: '',
    },
  ] as customFooterType;

  const GhgMrvSummaryProps: StatusKpiCardProps = {
    title: `GHGmrv summary\n(${TEXT.UNIT_MEASUREMENT.METRIC_TONNES_CO2_EQ})`,
    titleAlignment: 'center',
    footerType: ghgMrvSummaryDefinitions,
    customExportDefinitions: ghgMrvSummaryDefinitions,
    kpiData: {
      primaryValue: '',
      status: ghgMrvSummaryChartDomain.status,
      secondaryValue: ghgMrvSummaryChartDomain.data.co2.toString(),
      tertiaryValue: ghgMrvSummaryChartDomain.data.ch4.toString(),
      quaternaryValue: ghgMrvSummaryChartDomain.data.n2o.toString(),
    },
    sectionVisibility: {
      primaryValue: false,
      footer: true,
    },
    isFeatureFlagEnabled: true,
  };

  // Organize KPIs with feature flags in arrays with predefined order
  const euKPIDataMap = [
    {
      props: hasPlannedValue ? EuaAgainstPlannedProps : EuaNoPlannedProps,
      flag: true,
    },
    { props: EuaAgainstFleetProps, flag: true },
  ];

  // Filter and map the arrays based on feature flags
  const euKPIData = euKPIDataMap
    .filter((item) => item.flag)
    .map((item) => item.props);

  const euMrvKpiData = ghgMrvPart2FeatureEnabled
    ? ghgMrvGhgKpiSummaryStoryEnabled && ghgMrvGhgKpiStoryEnabled
      ? [GhgMrvEmissionProps, GhgMrvSummaryProps]
      : ghgMrvGhgKpiStoryEnabled
      ? [GhgMrvEmissionProps]
      : ghgMrvGhgKpiSummaryStoryEnabled
      ? [GhgMrvSummaryProps]
      : []
    : [];

  const { setCardData } = useContext(ExcelExportContext);

  useEffect(() => {
    setCardData(
      [
        ...euKPIData,
        ...flattenYearlyStatusKpiCards(yearlyFuelEuKPIData),
        ...euMrvKpiData,
      ],
      section
    );
  }, [loading]);

  if (loading) {
    return <KpiCardSkeleton kpiCards={euKPIData} />;
  }

  if (error) {
    return <KpiCardError kpiCards={euKPIData} />;
  }

  return (
    <Box sx={{ marginTop: 1 }}>
      <KpiCardGridContainer>
        <RenderKPIGroup title={'EU ETS'} kpiData={euKPIData} />
        <KpiCardDivider />
        <RenderKPIGroup title={'EU MRV'} kpiData={euMrvKpiData} />
      </KpiCardGridContainer>
      {yearlyFuelEuKPIData.map((kpi) => (
        <KpiCardGridContainer key={kpi.year}>
          <RenderKPIGroup
            title={
              yearlyFuelEuKPIData.length > 1
                ? `${TEXT.ENVIRONMENTAL_MONITOR.FUEL_EU.TITLE} - ${kpi.year}`
                : TEXT.ENVIRONMENTAL_MONITOR.FUEL_EU.TITLE
            }
            kpiData={kpi.data}
          />
        </KpiCardGridContainer>
      ))}
      <Box display='flex' flexDirection='column' sx={{ marginTop: 2 }}>
        {fuelEuGhgTrendChartFeatureEnabled && (
          <FuelEUGhgIntensityAndUsageTrendChart />
        )}
        <EUAMuiChart />
      </Box>
    </Box>
  );
};
