import { Box } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetVesselKPIs } from 'routes/environmental-monitor/services/vessel.service';
import { KpiCardError } from 'shared/components/error/kpi-card-error.component';
import { KpiCardSkeleton } from 'shared/components/kpi-card-skeleton/kpi-card-skeleton.component';
import { sectionContext } from 'shared/components/section/section.component';
import {
  GridItemKpiContainer,
  KpiCardGridContainer,
  StatusKpiCard,
  StatusKpiCardProps,
} from 'shared/components/status-kpi-card/status-kpi-card.component';
import { TEXT } from 'shared/constants/text';
import { ExcelExportContext } from 'shared/models/excel-export.context.model';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { useFeatureToggleContext } from 'shared/components/contexts/featureToggleProvider.component';
import { FEATURE_FLAG } from 'shared/constants/feature-flag';

export const VesselKPI = () => {
  const { id } = useParams();
  const { utcDateRange } = useContext(NavBarContext);
  const { data, loading, error } = useGetVesselKPIs(id, utcDateRange);
  const { featureFlags } = useFeatureToggleContext();

  const AERCardProps: StatusKpiCardProps = {
    title: 'AER',
    footerType: 'required',
    kpiData: data?.aer,
    footerUnits: TEXT.FORMULAS.AER,
    sectionVisibility: { footer: true },
    isFeatureFlagEnabled:
      featureFlags[FEATURE_FLAG.VESSEL_OVERVIEW_IMO_AER_KPI_CARD] ?? true,
  };

  const CIIRatingCardProps: StatusKpiCardProps = {
    title: 'CII Rating',
    footerType: 'required',
    kpiData: data?.ciiRating,
    units: data?.ciiRating?.quaternaryValue ?? '',
    footerUnits: TEXT.FORMULAS.CII,
    sectionVisibility: { footer: true },
    isFeatureFlagEnabled:
      featureFlags[FEATURE_FLAG.VESSEL_OVERVIEW_IMO_CII_KPI_CARD] ?? true,
  };

  const EEOICardProps: StatusKpiCardProps = {
    title: 'EEOI',
    footerType: 'target',
    kpiData: data?.eeoi,
    footerUnits: TEXT.FORMULAS.EEOI,
    sectionVisibility: { footer: true },
    isFeatureFlagEnabled:
      featureFlags[FEATURE_FLAG.VESSEL_OVERVIEW_IMO_EEOI_KPI_CARD] ?? true,
  };

  const CIIYearToDateCardProps: StatusKpiCardProps = {
    title: 'CII Year to Date',
    footerType: 'required',
    kpiData: data?.ciiYearToDate,
    units: data?.ciiYearToDate?.quaternaryValue ?? '',
    footerUnits: TEXT.FORMULAS.CII,
    sectionVisibility: { footer: true },
    fixedTimeline: true,
    isFeatureFlagEnabled:
      featureFlags[FEATURE_FLAG.VESSEL_OVERVIEW_IMO_CII_YTD_KPI_CARD] ?? true,
  };

  const CIIForecastCardProps: StatusKpiCardProps = {
    title: 'CII End of Year Forecast',
    footerType: 'required',
    kpiData: data?.ciiForecast,
    units: data?.ciiForecast?.quaternaryValue ?? '',
    footerUnits: TEXT.FORMULAS.CII,
    sectionVisibility: { footer: true },
    fixedTimeline: true,
    isFeatureFlagEnabled:
      featureFlags[FEATURE_FLAG.VESSEL_OVERVIEW_IMO_CII_EOY_KPI_CARD] ?? true,
  };

  const kpiData = [
    AERCardProps,
    CIIRatingCardProps,
    EEOICardProps,
    CIIYearToDateCardProps,
    CIIForecastCardProps,
  ];

  const { setCardData } = useContext(ExcelExportContext);
  const section = useContext(sectionContext);

  useEffect(() => {
    setCardData(kpiData, section);
  }, [loading]);

  if (loading) {
    return <KpiCardSkeleton kpiCards={kpiData} />;
  }

  if (error) {
    return <KpiCardError kpiCards={kpiData} />;
  }

  return (
    <Box>
      {kpiData.some((cardProps) => cardProps.isFeatureFlagEnabled) && (
        <KpiCardGridContainer>
          {kpiData
            .filter((cardProps) => cardProps.isFeatureFlagEnabled)
            .map((cardProps) => (
              <GridItemKpiContainer key={cardProps.title}>
                <StatusKpiCard {...cardProps} />
              </GridItemKpiContainer>
            ))}
        </KpiCardGridContainer>
      )}
    </Box>
  );
};
