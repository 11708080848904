import React, { useState, useEffect } from 'react';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import ListSubheader from '@mui/material/ListSubheader';
import { styled } from '@mui/material/styles';
import { FormTextField } from './input-fields';

interface DefaultOption {
  group?: string;
  abbreviation?: string;
}

export interface ValidatedAutocompleteProps<T extends DefaultOption>
  extends Omit<
    AutocompleteProps<T, false, false, false>,
    'value' | 'onChange' | 'renderInput' | 'renderGroup'
  > {
  validator?: (value: T | null) => string;
  formCheck?: (isValid: boolean) => void;
  label: string;
  required?: boolean;
  value: T | null;
  onChange?: (event: React.SyntheticEvent, newValue: T | null) => void;
  options: T[];
}

const StyledGroupHeader = styled(ListSubheader)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.09)' : 'blue',
  color: 'white',
  fontWeight: 'bold',
  lineHeight: 3.5,
}));

const StyledGroupList = styled('ul')({
  paddingLeft: 0,
  margin: 0,
});

const ValidatedAutocomplete = <T extends DefaultOption>({
  validator,
  formCheck,
  label,
  required,
  value,
  onChange,
  options,
  ...other
}: ValidatedAutocompleteProps<T>) => {
  const [error, setError] = useState<string>('');
  const [selectedValue, setSelectedValue] = useState<T | null>(value);

  useEffect(() => {
    setError('');
    setSelectedValue(value);
  }, [value]);

  const handleChange = (event: React.SyntheticEvent, newValue: T | null) => {
    const errorMessage = validator ? validator(newValue) : '';
    setError(errorMessage);
    if (formCheck) formCheck(errorMessage === '');
    setSelectedValue(newValue);
    if (onChange) onChange(event, newValue);
  };

  return (
    <Autocomplete
      options={options}
      groupBy={(option) => option.group || ''}
      getOptionLabel={(option) => option.abbreviation || ''}
      value={selectedValue}
      onChange={handleChange}
      renderInput={(params: any) => (
        <FormTextField
          {...params}
          label={label}
          variant='filled'
          error={!!error}
          helperText={error}
          required={required}
        />
      )}
      renderGroup={(params) => (
        <li key={params.key}>
          <StyledGroupHeader>{params.group}</StyledGroupHeader>
          <StyledGroupList>{params.children}</StyledGroupList>
        </li>
      )}
      {...other}
    />
  );
};

export default ValidatedAutocomplete;
