import gql from 'graphql-tag';

export const CALCULATE_PLANNED_ITINERARY = gql`
  query CalculatePlannedItinerary(
    $id: String!
    $startDate: DateTime!
    $endDate: DateTime!
    $plannedItinerary: PlannedItineraryInput!
  ) {
    vessel(
      id: $id
      dateRange: { startDateTime: $startDate, endDateTime: $endDate }
    ) {
      id
      plannedItineraryCalculations(plannedItinerary: $plannedItinerary) {
        id
        departurePortName
        arrivalPortName
        requiredCii
        ytdCii
        eoyCii
        eoyCiiStatus
        eoyCiiRating
        eoyCiiDelta
        eoyCiiPercentage
        plannedItineraryVerseYtdAverageSpeedDifference
        plannedItineraryVerseYtdIdleTimePercentage
        plannedItinerary {
          itineraryName
          totalDistance
          totalCO2
          idlePercentage
          averageSpeed
          estimatedCii
          ciiStatus
          ciiRating
          ciiDelta
          ciiPercentage
          estimatedEeoi
          eeoiStatus
          eeoiDelta
          eeoiPercentage
          eeoiTarget
          estimatedEuaLiability
          euaStatus
          euaDelta
          euaPercentage
          euaPlanned
          ghgIntensity
          ghgIntensityStatus
          complianceBalance
          fuelEuPenalty
          estGhgIntensity
          estGhgIntensityStatus
          estComplianceBalance
          estFuelEuPenalty
          estGhgMrv
          ghgMrv
          co2Mrv
          ch4Mrv
          n2OMrv
          fuelSlippage
        }
      }
    }
  }
`;
