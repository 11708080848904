import { ThemeProvider } from '@mui/material/styles';
import PageWrapper from 'shared/components/page-wrapper/page-wrapper.component';
import { Section } from 'shared/components/section/section.component';
import { theme } from 'styles/theme';
import { VesselDetailHeader } from './vessel-detail-header.component';
import { VesselKPI } from './vessel-kpi.component';
import { VoyageComparison } from './voyage-comparison/voyage-comparison.component';
import { vesselPageTitles } from 'routes/environmental-monitor/view-models/titles.viewModel';
import { ExportableSections } from 'shared/utils/valid-sections';
import { ItineraryComparison } from '../vesselPlannedItinerary/plannedItinerary.component';
import { VesselEUkpi } from './vessel-eu.component';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { useGetVesselKPIs } from 'routes/environmental-monitor/services/vessel.service';
import { Box } from '@mui/system';
import { CIIRatingsChart } from './cii-ratings/cii-ratings-chart.component';
import { AERChartMui } from './aer/aer-chart-mui.component';
import { useFeatureToggleContext } from 'shared/components/contexts/featureToggleProvider.component';
import { FEATURE_FLAG } from 'shared/constants/feature-flag';

export function VesselDetail() {
  const { id } = useParams();
  const { utcDateRange } = useContext(NavBarContext);
  const vesselKpiDomain = useGetVesselKPIs(id, utcDateRange);
  const { featureFlags } = useFeatureToggleContext();

  const displayImoOverview =
    featureFlags[FEATURE_FLAG.VESSEL_OVERVIEW_IMO_AER_KPI_CARD] ||
    featureFlags[FEATURE_FLAG.VESSEL_OVERVIEW_IMO_CII_KPI_CARD] ||
    featureFlags[FEATURE_FLAG.VESSEL_OVERVIEW_IMO_EEOI_KPI_CARD] ||
    featureFlags[FEATURE_FLAG.VESSEL_OVERVIEW_IMO_CII_YTD_KPI_CARD] ||
    featureFlags[FEATURE_FLAG.VESSEL_OVERVIEW_IMO_CII_EOY_KPI_CARD];

  return (
    <ThemeProvider theme={theme}>
      <PageWrapper>
        <VesselDetailHeader />
        {displayImoOverview && (
          <Section
            id={ExportableSections['IMO-Overview']}
            title={vesselPageTitles.imoOverview}
            exportSection={true}
            exportOptions={{
              includePDF: false,
              includeCardData: true,
              introductoryRows: { includeVesselName: true },
            }}
            childComponent={<VesselKPI />}
          />
        )}
        <Box
          display='flex'
          flexDirection='column'
          sx={{ marginTop: displayImoOverview ? -3 : 0, marginBottom: 2 }}
        >
          <CIIRatingsChart />
          <AERChartMui />
        </Box>

        <Section
          id={ExportableSections['EU-Overview']}
          title={vesselPageTitles.euOverview}
          exportSection={true}
          exportOptions={{
            includePDF: false,
            includeCardData: true,
            introductoryRows: { includeVesselName: true },
          }}
          childComponent={<VesselEUkpi vesselKpiDomain={vesselKpiDomain} />}
        />
        <Section
          id={vesselPageTitles.voyageLegsComparisonId}
          title={vesselPageTitles.voyageLegEmissionComparison}
          exportSection={true}
          exportOptions={{
            includePDF: false,
            introductoryRows: { includeVesselName: true },
          }}
          childComponent={<VoyageComparison />}
          preventUnmount={true}
        />

        <Section
          id={'EM-Planned Itinerary'}
          title={'Vessel Planned Itinerary'}
          exportSection={true}
          exportOptions={{
            includePDF: false,
            introductoryRows: { includeVesselName: true },
          }}
          childComponent={
            <ItineraryComparison vesselKpiDomain={vesselKpiDomain} />
          }
        />
      </PageWrapper>
    </ThemeProvider>
  );
}
