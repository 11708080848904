import { Box, Typography } from '@mui/material';

import {
  customFooterType,
  KpiCardDivider,
  KpiCardGridContainer,
  RenderKPIGroup,
  StatusKpiCardProps,
} from 'shared/components/status-kpi-card/status-kpi-card.component';
import { TEXT } from 'shared/constants/text';
import { toStatusEnum } from 'shared/utils/status-utils';
import { formatNumber, isNumeric } from 'shared/utils/float-utils';
import { DateRange } from 'shared/utils/date-utc-helper';
import React, { useContext, useEffect } from 'react';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { useGetFleetKpis } from 'routes/environmental-monitor/services/fleet.service';
import { NA_VALUE_INDICATOR } from 'shared/components/datagrid/DataGrid.component';
import { KpiCardError } from 'shared/components/error/kpi-card-error.component';
import { KpiCardSkeleton } from 'shared/components/kpi-card-skeleton/kpi-card-skeleton.component';
import { ExcelExportContext } from 'shared/models/excel-export.context.model';
import { FuelUsageChart } from './fueleu-fuel-usage-chart.component';
import { typography } from 'styles/typography';
import { useFeatureToggleContext } from 'shared/components/contexts/featureToggleProvider.component';
import { FEATURE_FLAG } from 'shared/constants/feature-flag';
import { VesselKPIDataMapper } from 'routes/environmental-monitor/mappers/vessel-kpi-data.mapper';
import { IFleetKpiDomain } from 'routes/environmental-monitor/models/fleet-kpi.model';
import { Status } from 'routes/environmental-monitor/models/gql/vessel-kpi-gql.model';
import { ExportableSections } from 'shared/utils/valid-sections';
import { flattenYearlyStatusKpiCards } from 'shared/utils/export/excel';
import { FuelEuKpi, KpiStatus } from '../../../../_gql/graphql';

export type FleetEUKpiDataSummaryProps = {
  dateRange: DateRange | null;
};

export const FleetEUKpiDataSummary: React.FC<FleetEUKpiDataSummaryProps> = ({
  dateRange,
}) => {
  const { featureFlags } = useFeatureToggleContext();

  const fleetGhgKpiFeatureEnabled = featureFlags[FEATURE_FLAG.MRV_PART_2];
  const fleetGhgKpiStoryEnabled =
    featureFlags[FEATURE_FLAG.FLEETOVERVIEW_KPIS_GHGKPI];
  const fleetGhgIntensityKpiCardEnabled =
    featureFlags[FEATURE_FLAG.FLEETOVERVIEW_GHG_INTENSITY_KPI_CARD];
  const fleetWellToWakeSummaryKpiCardEnabled =
    featureFlags[FEATURE_FLAG.FLEETOVERVIEW_WELL_TO_WAKE_SUMMARY_KPI_CARD];

  const { imoNumbers } = useContext(NavBarContext);
  const { setCardData } = useContext(ExcelExportContext);
  const section = ExportableSections['EU-Overview'];
  const { data, loading, error } = useGetFleetKpis(dateRange, imoNumbers);
  const ghg = data?.kpis?.ghgMrv?.ghgByYear
    ?.map((v) => v.value)
    .reduce((a, b) => a + b, 0);
  const liability = data?.kpis?.eua?.liability;
  const isNoReportsError = liability === -1;
  const planned = data?.kpis?.eua?.planned;
  const status = toStatusEnum(data?.kpis?.eua?.status ?? '');
  const isPlanned = planned && planned > 0;
  const liabilityPercent = data?.kpis?.eua?.value ?? undefined;

  const validLiabilityPercent =
    isPlanned && liabilityPercent ? liabilityPercent : NA_VALUE_INDICATOR;

  const validLiability =
    liability != null ? liability?.toFixed() : NA_VALUE_INDICATOR;

  const ghgMrvValue: IFleetKpiDomain | undefined = fleetGhgKpiFeatureEnabled
    ? {
        ghgMrvTotal: VesselKPIDataMapper.toGhgMrvTotalDomain(
          data?.kpis?.ghgMrv ?? undefined
        ),
        ghgMrvFuelEmissions: VesselKPIDataMapper.toGhgMrvFuelEmissions(
          data?.kpis?.ghgMrv ?? undefined
        ),
        ghgMrvFuelSlippageTotal: VesselKPIDataMapper.toGhgMrvFuelSlipDomain(
          data?.kpis?.ghgMrv ?? undefined
        ),
      }
    : undefined;

  const EUACardProps: StatusKpiCardProps = {
    title: 'EU Allowances Against Planned',
    sectionVisibility: { footer: true },
    footerType: 'planned',
    kpiData: {
      primaryValue:
        isNoReportsError === true
          ? TEXT.ERROR_MESSAGES.EMPTY_DASHES
          : `${validLiabilityPercent}`, // EUA Val
      secondaryValue:
        isNoReportsError === true
          ? TEXT.ERROR_MESSAGES.EMPTY_DASHES
          : validLiability, // Liability
      tertiaryValue: `${planned || 0}`, // Planned
      quaternaryValue: '', // Deviation
      status: isPlanned && liabilityPercent ? status : 'unknown',
      unit: TEXT.UNIT_MEASUREMENT.PERCENTAGE,
    },
    isFeatureFlagEnabled: true,
  };

  const passedCardProps: StatusKpiCardProps = {
    title: 'EU Allowances',
    kpiData: {
      status: isNumeric(liability) ? 'graph' : 'unknown',
      primaryValue: formatNumber(liability),
    },
    isFeatureFlagEnabled: true,
  };

  const yearlyFuelEuKPIData =
    (data?.kpis?.fuelEU === null ||
    data?.kpis?.fuelEU === undefined ||
    data?.kpis?.fuelEU.length === 0
      ? [
          {
            complianceBalance: null,
            complianceBalanceAgainstFleetPercentage: null,
            complianceBalanceAgainstFleetStatus: KpiStatus.None,
            fleetComplianceBalance: null,
            fleetFuelPenalty: null,
            fuelPenalty: null,
            fuelPenaltyAgainstFleetPercentage: null,
            fuelUsage: [
              {
                classification: 'Fossil',
                total: 0,
                consumptions: [],
                __typename: 'FuelUsage',
              },
              {
                classification: 'BioFuel',
                total: 0,
                consumptions: [],
                __typename: 'FuelUsage',
              },
              {
                classification: 'e-Fuel',
                total: 0,
                consumptions: [],
                __typename: 'FuelUsage',
              },
            ],
            ghgIntensity: null,
            ghgIntensityStatus: KpiStatus.None,
            ghgIntensityTarget: null,
            onShorePowerSupply: false,
            tankToWake: null,
            wellToTank: null,
            wellToWake: null,
            wellToWakeStatus: KpiStatus.None,
            windRewardFactor: null,
            year: dateRange?.endDate?.year,
          } as FuelEuKpi,
        ]
      : // eslint-disable-next-line no-unsafe-optional-chaining
        data?.kpis?.fuelEU
    ).map((fuelEU) => {
      const enabledKpis: StatusKpiCardProps[] = [];

      if (fleetGhgIntensityKpiCardEnabled) {
        const GHGIntensityPropsDummyData: StatusKpiCardProps = {
          title: 'GHG Intensity',
          sectionVisibility: { footer: true },
          units: TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ_PER_MEGAJOULE,
          footerType: [
            {
              title: 'Compliance Balance',
              value: formatNumber(fuelEU?.complianceBalance),
              unitLabel: TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ,
            },
            {
              title: `${TEXT.ENVIRONMENTAL_MONITOR.FUEL_EU.TITLE} Penalty`,
              value:
                TEXT.CURRENCY.EURO + ' ' + formatNumber(fuelEU?.fuelPenalty),
              unitLabel: '',
            },
          ] as customFooterType,
          customExportDefinitions: [
            {
              title: `Compliance Balance (${TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ})`,
              value: formatNumber(fuelEU?.complianceBalance),
              ignoreParentUnit: true,
            },
            {
              title: `${TEXT.ENVIRONMENTAL_MONITOR.FUEL_EU.TITLE} Penalty (${TEXT.CURRENCY.EURO})`,
              value: formatNumber(fuelEU?.fuelPenalty),
              ignoreParentUnit: true,
            },
          ] as customFooterType,
          kpiData: {
            primaryValue: formatNumber(fuelEU?.ghgIntensity, 2),
            status: toStatusEnum(fuelEU?.ghgIntensityStatus),
            secondaryValue: formatNumber(fuelEU?.complianceBalance),
            tertiaryValue: formatNumber(fuelEU?.fuelPenalty),
          },
          isFeatureFlagEnabled: fleetGhgIntensityKpiCardEnabled ?? false,
        };
        enabledKpis.push(GHGIntensityPropsDummyData);
      }

      if (fleetWellToWakeSummaryKpiCardEnabled) {
        const WellToWakeCardProps: StatusKpiCardProps = {
          title: `Well to Wake (${TEXT.UNIT_MEASUREMENT.WELL_TO_WAKE}) Summary\n(${TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ_PER_MEGAJOULE})`,
          titleAlignment: 'center',
          footerType: [
            {
              title: `Well to Tank (${TEXT.UNIT_MEASUREMENT.WELL_TO_TANK})`,
              value: formatNumber(fuelEU?.wellToTank, 2),
              unitLabel: '',
            },
            {
              title: `Tank to Wake (${TEXT.UNIT_MEASUREMENT.TANK_TO_WAKE})`,
              value: formatNumber(fuelEU?.tankToWake, 2),
              unitLabel: '',
            },
            {
              title: 'Wind Reward Factor',
              value: formatNumber(fuelEU?.windRewardFactor, 2),
              unitLabel: '',
            },
          ] as customFooterType,
          kpiData: {
            primaryValue: '',
            status:
              fuelEU?.wellToTank ||
              fuelEU?.tankToWake ||
              fuelEU?.windRewardFactor
                ? Status.graph
                : Status.none,
            secondaryValue: formatNumber(fuelEU?.wellToTank, 2),
            tertiaryValue: formatNumber(fuelEU?.tankToWake, 2),
            quaternaryValue: formatNumber(fuelEU?.windRewardFactor, 2),
          },
          sectionVisibility: {
            primaryValue: false,
            footer: true,
          },
          isFeatureFlagEnabled: fleetWellToWakeSummaryKpiCardEnabled ?? false,
        };
        enabledKpis.push(WellToWakeCardProps);
      }

      enabledKpis.push(FuelUsageChart(fuelEU.fuelUsage ?? [], featureFlags));

      return { year: fuelEU.year, data: enabledKpis };
    }) ?? [];

  const GhgMrvEmissionProps: StatusKpiCardProps = {
    title: 'Greenhouse Gas (GHG) Emission',
    titleAlignment: 'center',
    footerType: (
      <Box sx={{ textAlign: 'center', width: '100%' }}>
        <Typography
          sx={{ fontSize: typography.fontSize['10'], lineHeight: '100%' }}
        >
          {formatNumber(ghg, 2)}
        </Typography>
        <Box fontSize={'medium'} sx={{ color: 'gray' }}>
          tCO₂eq
        </Box>
        <Box sx={{ mt: 3 }}>MRV, Tank to Wake</Box>
      </Box>
    ),
    kpiData: {
      primaryValue: '',
      status: ghg && ghg > 0 ? Status.graph : Status.none,
    },
    customExportDefinitions: [
      {
        title: `MRV, Tank to Wake (${TEXT.UNIT_MEASUREMENT.METRIC_TONNES_CO2_EQ})`,
        value: formatNumber(ghg, 2),
        unitLabel: '',
      },
    ] as customFooterType,
    hideFooterDivider: true,
    sectionVisibility: {
      primaryValue: false,
      footer: true,
    },
    isFeatureFlagEnabled: true,
  };

  const ghgMrvSummaryChartDomain =
    VesselKPIDataMapper.toGhgMrvSummaryChartDomain(
      ghgMrvValue?.ghgMrvFuelEmissions
    );

  const ghgMrvSummaryDefinitions = [
    {
      title: `Carbon Dioxide (${TEXT.UNIT_MEASUREMENT.CO2})`,
      value: ghgMrvSummaryChartDomain.data.co2,
      unitLabel: '',
    },
    {
      title: `Methane (${TEXT.UNIT_MEASUREMENT.CH4})`,
      value: ghgMrvSummaryChartDomain.data.ch4,
      unitLabel: '',
    },
    {
      title: `Nitrous Oxide (${TEXT.UNIT_MEASUREMENT.N20})`,
      value: ghgMrvSummaryChartDomain.data.n2o,
      unitLabel: '',
    },
  ] as customFooterType;

  const GhgMrvSummaryProps: StatusKpiCardProps = {
    title: `GHGmrv summary\n(${TEXT.UNIT_MEASUREMENT.METRIC_TONNES_CO2_EQ})`,
    titleAlignment: 'center',
    footerType: ghgMrvSummaryDefinitions,
    customExportDefinitions: ghgMrvSummaryDefinitions,
    kpiData: {
      primaryValue: '',
      status: ghgMrvSummaryChartDomain.status,
      secondaryValue: ghgMrvSummaryChartDomain.data.co2.toString(),
      tertiaryValue: ghgMrvSummaryChartDomain.data.ch4.toString(),
      quaternaryValue: ghgMrvSummaryChartDomain.data.n2o.toString(),
    },
    sectionVisibility: {
      primaryValue: false,
      footer: true,
    },
    isFeatureFlagEnabled: true,
  };

  // Organize KPIs with feature flags in arrays with predefined order
  const euKPIDataMap = [
    {
      props: isPlanned ? EUACardProps : passedCardProps,
      featureFlag: true,
    },
  ];

  const GHGKPIDataMap = [
    {
      props: GhgMrvEmissionProps,
      featureFlag: fleetGhgKpiFeatureEnabled && fleetGhgKpiStoryEnabled,
    },
    {
      props: GhgMrvSummaryProps,
      featureFlag: fleetGhgKpiFeatureEnabled,
    },
  ];

  // Filter based on enabled feature flags and map to the ordered props array
  const euKPIData = euKPIDataMap
    .filter((item) => item.featureFlag)
    .map((item) => item.props);

  const GHGKPIData = GHGKPIDataMap.filter((item) => item.featureFlag).map(
    (item) => item.props
  );

  useEffect(() => {
    setCardData(
      [
        ...euKPIData,
        ...GHGKPIData,
        ...flattenYearlyStatusKpiCards(yearlyFuelEuKPIData),
      ],
      section
    );
  }, [loading]);

  if (error) {
    return (
      <>
        <KpiCardError
          kpiCards={[
            {
              title: 'EUA',
              showVerticalDivider: true,
              showBoxContainerTitle: true,
              boxContainerTitleText: 'EU ETS',
            },
            {
              title: 'GHG Emission',
              showVerticalDivider: false,
              showBoxContainerTitle: true,
              boxContainerTitleText: 'EU MRV',
            },
            {
              title: 'GHG Summary',
              showVerticalDivider: false,
            },
          ]}
        />
        <KpiCardError
          kpiCards={[
            {
              title: 'GHG Intensity',
              showVerticalDivider: false,
              showBoxContainerTitle: true,
              boxContainerTitleText: TEXT.ENVIRONMENTAL_MONITOR.FUEL_EU.TITLE,
            },
            {
              title: 'Well to Wake Summary',
            },
            {
              title: 'Fuel Usage',
            },
          ]}
        />
      </>
    );
  }

  if (loading || data === undefined)
    return (
      <KpiCardSkeleton
        kpiCards={[
          { sectionVisibility: { footer: true } },
          { sectionVisibility: { footer: true } },
          { sectionVisibility: { footer: true } },
        ]}
      />
    );

  return (
    <>
      <KpiCardGridContainer>
        {/* EU ETS Kpis  */}
        <RenderKPIGroup title={'EU ETS'} kpiData={euKPIData} />

        <KpiCardDivider />

        {/* GHG Kpis  */}
        <RenderKPIGroup title={'EU MRV'} kpiData={GHGKPIData} />
      </KpiCardGridContainer>

      {/* Fuel EU Kpis  */}
      {yearlyFuelEuKPIData.map((kpi) => (
        <KpiCardGridContainer key={kpi.year}>
          <RenderKPIGroup
            title={
              yearlyFuelEuKPIData.length > 1
                ? `${TEXT.ENVIRONMENTAL_MONITOR.FUEL_EU.TITLE} - ${kpi.year}`
                : TEXT.ENVIRONMENTAL_MONITOR.FUEL_EU.TITLE
            }
            kpiData={kpi.data}
          />
        </KpiCardGridContainer>
      ))}
    </>
  );
};
