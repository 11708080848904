export interface IFuelType {
  id: number;
  name: string;
  abbreviation: string;
  enumValue: number;
  classificationName: string;
}

export const emptyFuelType: IFuelType = {
  id: 1,
  abbreviation: '',
  name: '',
  enumValue: 0,
  classificationName: '',
};
